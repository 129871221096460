/* eslint-disable max-statements, react/require-optimization, vars-on-top, no-var, init-declarations, no-magic-numbers, react/destructuring-assignment, prefer-destructuring, camelcase, no-dupe-else-if, object-shorthand, react/no-set-state, no-alert, no-ternary, lines-between-class-members, prefer-template, max-lines-per-function, react/jsx-handler-names, no-nested-ternary, react/jsx-no-bind, max-lines */

import { Col, Container, Row } from "reactstrap"
import ActiveFetches from "./ActiveFetches"
import AvailableDrivers from "./AvailableDrivers"
import React from "react"
import Request from "../lib/Request"
import Select from "@fetch/ui/Select"

const initialRegionId = 1

export default class AdminDashboard extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      regionId: initialRegionId,
      regions: []
    }

    this.changeRegion = this.changeRegion.bind(this)
  }

  componentDidMount() {
    this.loadData()
  }

  loadData() {
    Request.get("regions")
      .then((regionsRes) => {
        const regions = regionsRes.data.result.regions
        this.setState({ regions: regions })
      })
      .catch((err) => {
        alert(err.response ? err.response.data.context.message : err)
      })
  }

  changeRegion(regionId) {
    this.setState({ regionId: regionId })
  }

  render() {
    return (
      <div>
        <Container fluid>
          <Row style={{ marginTop: "32px" }}>
            <Col md="9" xs="0" />
            <Col md="3" xs="6">
              <Select
                label="Region"
                name="region"
                onChange={this.changeRegion}
                options={this.state.regions.map((region) => ({
                  label: region.name,
                  value: region.id
                }))}
                value={this.state.regionId}
              />
            </Col>
          </Row>
          <Row>
            <Col xs="12">
              <ActiveFetches regionId={this.state.regionId} />
            </Col>
            <Col xs="12">
              <AvailableDrivers regionId={this.state.regionId} />
            </Col>
          </Row>
        </Container>
      </div>
    )
  }
}
