/* eslint-disable max-statements, react/require-optimization, vars-on-top, no-var, init-declarations, no-magic-numbers, react/destructuring-assignment, prefer-destructuring, camelcase, no-dupe-else-if, object-shorthand, react/no-set-state, no-alert, no-ternary, lines-between-class-members, prefer-template, max-lines-per-function, react/jsx-handler-names, no-nested-ternary, react/jsx-no-bind, max-lines, react/no-access-state-in-setstate, class-methods-use-this, react/jsx-max-depth, no-param-reassign, id-length */

import { Col, Container, Row, Table } from "reactstrap"
import { Button } from "@fetch/ui"
import LoadingIndicator from "./LoadingIndicator"
import React from "react"
import RegionForm from "./RegionForm"
import Request from "../lib/Request"

require("../lib/Extensions")

export default class Regions extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      editRegion: false,
      isLoading: false,
      newRegion: false,
      region: null,
      regions: []
    }

    this.loadData = this.loadData.bind(this)

    this.newRegion = this.newRegion.bind(this)
    this.cancelNewRegion = this.cancelNewRegion.bind(this)
    this.saveNewRegion = this.saveNewRegion.bind(this)

    this.editRegion = this.editRegion.bind(this)
    this.cancelEditRegion = this.cancelEditRegion.bind(this)
    this.saveEditRegion = this.saveEditRegion.bind(this)
  }

  componentDidMount() {
    this.loadData()
  }

  loadData() {
    this.setState({ isLoading: true })

    Request.get("regions")
      .then((regionsRes) => {
        const regions = regionsRes.data.result.regions
        this.setState({ isLoading: false, regions: regions })
      })
      .catch((err) => {
        alert(err.response ? err.response.data.context.message : err)
        this.setState({ isLoading: false })
      })
  }

  newRegion() {
    this.setState({ newRegion: true })
  }
  cancelNewRegion() {
    this.setState({ newRegion: false })
  }
  saveNewRegion(data) {
    return Request.post("regions", data).then(() => {
      this.setState({ newRegion: false })
      this.loadData()
    })
  }

  editRegion(region) {
    this.setState({ editRegion: true, region: region })
  }
  cancelEditRegion() {
    this.setState({ editRegion: false })
  }
  saveEditRegion(data) {
    return Request.post("regions/" + data.id, data).then(() => {
      this.setState({ editRegion: false })
      this.loadData()
    })
  }

  render() {
    return (
      <div>
        <Container fluid>
          <Row>
            <Col xs="8">
              <h1>
                {"Regions"}{" "}
                <LoadingIndicator isLoading={this.state.isLoading} />
              </h1>
            </Col>
            <Col className="d-flex align-items-center" xs="4">
              <Button
                label="+ New Region"
                onClick={this.newRegion}
                outlined
                secondary
                small
              />
            </Col>
          </Row>
        </Container>
        <RegionForm
          isOpen={this.state.newRegion}
          onCancel={this.cancelNewRegion}
          onSave={this.saveNewRegion}
        />
        <RegionForm
          isOpen={this.state.editRegion}
          onCancel={this.cancelEditRegion}
          onSave={this.saveEditRegion}
          region={this.state.region}
        />
        <Table bordered responsive striped>
          <thead>
            <tr>
              <th width="100">{"Actions"}</th>
              <th width="80">{"Id"}</th>
              <th>{"Name"}</th>
              <th>{"State"}</th>
            </tr>
          </thead>
          <tbody>
            {this.state.regions.map((region) => (
              <tr key={region.id}>
                <td>
                  <Button
                    label="Edit"
                    onClick={() => this.editRegion(region)}
                    outlined
                    secondary
                    small
                  />
                </td>
                <td>{region.id}</td>
                <td>{region.name}</td>
                <td>{region.state}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    )
  }
}
