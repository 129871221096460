// @flow

export default Object.freeze({
  // eslint-disable-next-line prefer-template
  BaseUrl: window.location.protocol + "//" + window.location.host,
  UserType: {
    admin: "admin",
    customer: "customer",
    driver: "driver",
    merchant: "merchant"
  },
  Version: "5.8.0"
})
