import {
  Breadcrumb,
  BreadcrumbItem,
  Container,
  Row,
  Col,
  Table
} from "reactstrap"
import { GoChevronUp, GoChevronDown } from "react-icons/go"
import { Button } from "@fetch/ui"
import React from "react"
import Request from "../lib/Request"
import MenuItemOptionGroupForm from "./MenuItemOptionGroupForm"
import LoadingIndicator from "./LoadingIndicator"
import styled from "styled-components"

const Buttons = styled.td`
  column-gap: 10px;
  display: grid;
  grid-template-columns: auto auto;
  row-gap: 10px;
`

export default class MenuItemOptionGroups extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      merchant: null,
      isLoading: false,
      menu: null,
      menuItem: null,
      menuItemOptionGroups: [],
      newMenuItemOptionGroup: false,
      editMenuItemOptionGroup: false,
      menuItemOptionGroup: null
    }

    this.loadData = this.loadData.bind(this)

    this.newMenuItemOptionGroup = this.newMenuItemOptionGroup.bind(this)
    this.cancelNewMenuItemOptionGroup =
      this.cancelNewMenuItemOptionGroup.bind(this)
    this.saveNewMenuItemOptionGroup = this.saveNewMenuItemOptionGroup.bind(this)

    this.editMenuItemOptionGroup = this.editMenuItemOptionGroup.bind(this)
    this.cancelEditMenuItemOptionGroup =
      this.cancelEditMenuItemOptionGroup.bind(this)
    this.saveEditMenuItemOptionGroup =
      this.saveEditMenuItemOptionGroup.bind(this)

    this.deleteMenuItemOptionGroup = this.deleteMenuItemOptionGroup.bind(this)

    this.moveMenuItemOptionGroupUp = this.moveMenuItemOptionGroupUp.bind(this)
    this.moveMenuItemOptionGroupDown =
      this.moveMenuItemOptionGroupDown.bind(this)
    this.sortMenuItemOptionGroups = this.sortMenuItemOptionGroups.bind(this)

    this.navigateToMenuItemOptionsForMenuItemOptionGroup =
      this.navigateToMenuItemOptionsForMenuItemOptionGroup.bind(this)
  }

  componentDidMount() {
    this.loadData()
  }

  loadData() {
    this.setState({ isLoading: true })

    const getMerchant = Request.get(
      "merchants/" + this.props.match.params.merchant_id
    )
    const getMenu = Request.get("menus/" + this.props.match.params.menu_id)
    const getMenuItem = Request.get(
      "menu_items/" + this.props.match.params.menu_item_id
    )
    const getMenuItemOptionGroups = Request.get(
      "menu_item_option_groups?menu_item_id=" +
        this.props.match.params.menu_item_id
    )
    Promise.all([getMerchant, getMenu, getMenuItem, getMenuItemOptionGroups])
      .then((values) => {
        const [merchantRes, menuRes, menuItemRes, menuItemOptionGroupsRes] =
          values

        const merchant = merchantRes.data.result.merchant
        const menu = menuRes.data.result.menu
        const menuItem = menuItemRes.data.result.menuItem
        const menuItemOptionGroups =
          menuItemOptionGroupsRes.data.result.menuItemOptionGroups
        this.setState({
          merchant: merchant,
          menu: menu,
          menuItem: menuItem,
          menuItemOptionGroups: menuItemOptionGroups,
          isLoading: false
        })
      })
      .catch((err) => {
        alert(err.response ? err.response.data.context.message : err)
        this.setState({ isLoading: false })
      })
  }

  newMenuItemOptionGroup() {
    this.setState({ newMenuItemOptionGroup: true })
  }
  cancelNewMenuItemOptionGroup() {
    this.setState({ newMenuItemOptionGroup: false })
  }
  saveNewMenuItemOptionGroup(data) {
    console.log(data)

    return Request.post("menu_item_option_groups", data).then((res) => {
      console.log(data)
      this.setState({ newMenuItemOptionGroup: false })
      this.loadData()
    })
  }

  editMenuItemOptionGroup(menuItemOptionGroup) {
    console.log(menuItemOptionGroup)
    this.setState({
      editMenuItemOptionGroup: true,
      menuItemOptionGroup: menuItemOptionGroup
    })
  }
  cancelEditMenuItemOptionGroup() {
    this.setState({ editMenuItemOptionGroup: false })
  }
  saveEditMenuItemOptionGroup(data) {
    console.log(data)

    return Request.post("menu_item_option_groups/" + data.id, data).then(
      (res) => {
        console.log(res)
        this.setState({ editMenuItemOptionGroup: false })
        this.loadData()
      }
    )
  }

  deleteMenuItemOptionGroup(menuItemOptionGroup) {
    if (
      window.confirm(
        `Are you sure you want to delete ${menuItemOptionGroup.name}?`
      )
    ) {
      Request.post(
        "menu_item_option_groups/" + menuItemOptionGroup.id + "/delete"
      )
        .then((res) => {
          console.log(res)
          this.loadData()
        })
        .catch((err) => {
          alert(err.response ? err.response.data.context.message : err)
        })
    }
  }

  moveMenuItemOptionGroupUp(menuItemOptionGroup) {
    var menuItemOptionGroupIds = this.state.menuItemOptionGroups.map(
      (menuItemOptionGroup) => menuItemOptionGroup.id
    )
    const index = menuItemOptionGroupIds.indexOf(menuItemOptionGroup.id)
    if (index <= 0) {
      return
    }

    menuItemOptionGroupIds.splice(
      index - 1,
      0,
      menuItemOptionGroupIds.splice(index, 1)[0]
    )
    this.sortMenuItemOptionGroups(menuItemOptionGroupIds)
  }

  moveMenuItemOptionGroupDown(menuItemOptionGroup) {
    var menuItemOptionGroupIds = this.state.menuItemOptionGroups.map(
      (menuItemOptionGroup) => menuItemOptionGroup.id
    )
    const index = menuItemOptionGroupIds.indexOf(menuItemOptionGroup.id)
    if (index >= this.state.menuItemOptionGroups.length - 1) {
      return
    }

    menuItemOptionGroupIds.splice(
      index + 1,
      0,
      menuItemOptionGroupIds.splice(index, 1)[0]
    )

    this.sortMenuItemOptionGroups(menuItemOptionGroupIds)
  }

  sortMenuItemOptionGroups(menuItemOptionGroupIds) {
    Request.post(
      "menu_items/" +
        this.props.match.params.menu_item_id +
        "/sort_option_groups",
      { menuItemOptionGroupIds: menuItemOptionGroupIds }
    )
      .then(() => {
        this.loadData()
      })
      .catch((err) => {
        alert(err.response ? err.response.data.context.message : err)
      })
  }

  navigateToMenuItemOptionsForMenuItemOptionGroup(menuItemOptionGroupId) {
    console.log(menuItemOptionGroupId)
    window.location.href = `/merchants/${this.state.merchant.id}/menus/${this.state.menu.id}/items/${this.state.menuItem.id}/option_groups/${menuItemOptionGroupId}/options`
  }

  render() {
    return (
      <div>
        <Breadcrumb>
          <BreadcrumbItem>
            <a href="/merchants">Merchants</a>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <a
              href={
                this.state.merchant
                  ? "/merchants/" + this.state.merchant.id
                  : ""
              }
            >
              {this.state.merchant && this.state.merchant.name}
            </a>
          </BreadcrumbItem>
          <BreadcrumbItem>Menus</BreadcrumbItem>
          <BreadcrumbItem>
            <a
              href={
                this.state.merchant
                  ? "/merchants/" +
                    this.state.merchant.id +
                    "/menus/" +
                    this.state.menu.id +
                    "/items"
                  : ""
              }
            >
              {this.state.menu ? this.state.menu.name : ""}
            </a>
          </BreadcrumbItem>
          <BreadcrumbItem active>
            {this.state.menuItem && this.state.menuItem.name}
          </BreadcrumbItem>
        </Breadcrumb>
        <Container fluid>
          <Row>
            <Col xs="8">
              <h1>
                {this.state.menuItem && this.state.menuItem.name}{" "}
                <LoadingIndicator isLoading={this.state.isLoading} />
              </h1>
            </Col>
            <Col xs="4" className="d-flex align-items-center">
              <Button
                label="+ New Option Group"
                onClick={this.newMenuItemOptionGroup}
                outlined
                secondary
                small
              />
            </Col>
          </Row>
        </Container>
        <MenuItemOptionGroupForm
          isOpen={this.state.newMenuItemOptionGroup}
          onCancel={this.cancelNewMenuItemOptionGroup}
          onSave={this.saveNewMenuItemOptionGroup}
          menuItem={this.state.menuItem}
        />
        <MenuItemOptionGroupForm
          isOpen={this.state.editMenuItemOptionGroup}
          onCancel={this.cancelEditMenuItemOptionGroup}
          onSave={this.saveEditMenuItemOptionGroup}
          menuItemOptionGroup={this.state.menuItemOptionGroup}
          menuItem={this.state.menuItem}
        />
        <Table striped bordered responsive>
          <thead>
            <tr>
              <th>Actions</th>
              <th width="80">Id</th>
              <th>Name</th>
              <th>Min Selections</th>
              <th>Max Selections</th>
            </tr>
          </thead>
          <tbody>
            {this.state.menuItemOptionGroups.map((menuItemOptionGroup) => (
              <tr>
                <Buttons>
                  <Button
                    label="Edit"
                    onClick={() =>
                      this.editMenuItemOptionGroup(menuItemOptionGroup)
                    }
                    outlined
                    secondary
                    small
                  />
                  <Button
                    label="Delete"
                    onClick={() =>
                      this.deleteMenuItemOptionGroup(menuItemOptionGroup)
                    }
                    secondary
                    small
                  />
                  {menuItemOptionGroup.index > 0 ? (
                    <Button
                      onClick={() =>
                        this.moveMenuItemOptionGroupUp(menuItemOptionGroup)
                      }
                      iconEnd={<GoChevronUp />}
                      outlined
                      small
                    />
                  ) : (
                    ""
                  )}
                  {menuItemOptionGroup.index <
                  this.state.menuItemOptionGroups.length - 1 ? (
                    <Button
                      iconEnd={<GoChevronDown />}
                      onClick={() =>
                        this.moveMenuItemOptionGroupDown(menuItemOptionGroup)
                      }
                      outlined
                      small
                    />
                  ) : (
                    ""
                  )}
                  <Button
                    label="Options"
                    onClick={() =>
                      this.navigateToMenuItemOptionsForMenuItemOptionGroup(
                        menuItemOptionGroup.id
                      )
                    }
                    outlined
                    small
                  />
                </Buttons>
                <td>{menuItemOptionGroup.id}</td>
                <td>{menuItemOptionGroup.name}</td>
                <td>{menuItemOptionGroup.minSelections}</td>
                <td>{menuItemOptionGroup.maxSelections}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    )
  }
}
