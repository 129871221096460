import React from 'react';

import Request from '../lib/Request';

export default class Fetch extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fetchData: null
    };

    this.reloadData = this.reloadData.bind(this);
  }

  componentDidMount() {
    this.reloadData(this.props.match.params.id)
  }

  reloadData(fetchId) {
    Request.get('fetches/' + fetchId + '/text').then(res => {
      this.setState({ fetchData: res.data });
    }).catch(err => {
      console.log(err);
    })
  }

  render() {
    if (!this.state.fetchData) { return null }

    return (
      <pre style={{ whiteSpace: 'pre-wrap' }}>{this.state.fetchData}</pre>
    )
  }
}
