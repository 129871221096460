import React from 'react';

import Request from '../lib/Request';

export default class Driver extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      driverData: null
    }

    this.reloadData = this.reloadData.bind(this)
  }

  componentDidMount() {
    this.reloadData(this.props.match.params.id)
  }

  reloadData(driverId) {
    Request.get('drivers/' + driverId + '/profile').then(res => {
      this.setState({ driverData: res.data })
    }).catch(err => {
      console.log(err)
    })
  }

  render() {
    if (!this.state.driverData) { return null }

    return (
      <div dangerouslySetInnerHTML={{__html: this.state.driverData}} />
    )
  }
}
