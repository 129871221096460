import React from 'react'
import { Row, Col, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Input } from 'reactstrap'

import Request from '../lib/Request'
import FormButtons from './FormButtons'

export default class DriverForm extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isSaving: false
    }
  
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  componentDidMount() {
  }

  handleSubmit(event) {
    this.setState({ isSaving: true })

    event.preventDefault()

    const formData = new FormData(event.target)

    var uploadImage = Promise.resolve()

    if (this.uploadImageInput.files[0]) {
      const uploadImageData = new FormData()
      uploadImageData.append('image', this.uploadImageInput.files[0])
      uploadImage = Request.post('images', uploadImageData).then(res => {
        return res.data.result.image.id
      })
    }

    uploadImage.then(imageId => {
      var driverUserObject = {
        id: formData.get('id'),
        firstName: formData.get('firstName'),
        lastName: formData.get('lastName'),
        email: formData.get('email'),
        phoneNumber: formData.get('phoneNumber'),
        accountIdentifier: formData.get('accountIdentifier')
      }

      if (imageId) {
        driverUserObject.imageId = imageId
      }

      return this.props.onSave(driverUserObject)
    }).then(() => {
      this.setState({ isSaving: false })
    }).catch(err => {
      alert(err.response ? err.response.data.context.message : err)
      this.setState({ isSaving: false })
    })
  }

  render() {
    return (
      <Modal isOpen={this.props.isOpen}>
        <Form onSubmit={this.handleSubmit}>
          <Input type="hidden" name="id" value={this.props.driverUser ? this.props.driverUser.id : ''} />
          <ModalHeader>{this.props.driverUser ? 'Edit Driver' : 'New Driver'}</ModalHeader>
          <ModalBody>
            <FormGroup>
              <Row>
                <Col xs="6">
                  <Label>First Name</Label>
                  <Input type="text" name="firstName" defaultValue={this.props.driverUser ? this.props.driverUser.firstName : ''} />
                </Col>
                <Col xs="6">
                  <Label>Last Name</Label>
                  <Input type="text" name="lastName" defaultValue={this.props.driverUser ? this.props.driverUser.lastName : ''} />
                </Col>
                </Row>
            </FormGroup>
            <FormGroup>
              <Row>
                <Col xs="12">
                  <Label>Photo (current image: {this.props.driverUser ? this.props.driverUser.photoId : 'none'})</Label>
                  <Input type="file" name="image" innerRef={(ref) => { this.uploadImageInput = ref; }} />
                </Col>
              </Row>
            </FormGroup>
            <FormGroup>
              <Row>
                <Col xs="8">
                  <Label>{ this.props.driverUser ? 'Email' : 'Google Account Email' }</Label>
                  <Input type="email" name="email" defaultValue={this.props.driverUser ? this.props.driverUser.email : ''} />
                </Col>
                <Col xs="4">
                  <Label>Phone Number</Label>
                  <Input type="text" name="phoneNumber" defaultValue={this.props.driverUser ? this.props.driverUser.phoneNumber : ''} />
                </Col>
              </Row>
            </FormGroup>
            <FormGroup>
              <Row>
                <Col xs="12">
                  <Label>Account Identifier</Label>
                  <Input type="text" name="accountIdentifier" defaultValue={this.props.driverUser ? this.props.driverUser.accountIdentifier : ''} />
                </Col>
              </Row>
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <FormButtons isSaving={this.state.isSaving} onCancel={this.props.onCancel}>{this.props.driverUser ? 'Save' : 'Create Driver'}</FormButtons>
          </ModalFooter>
        </Form>
      </Modal>
    )
  }
}
