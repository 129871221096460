import { Container, Row, Col, Table, Badge } from "reactstrap"
import { Button } from "@fetch/ui"
import PlaceForm from "./PlaceForm"
import PlaceMenusForm from "./PlaceMenusForm"
import React from "react"
import Request from "../lib/Request"
import styled from "styled-components"

require("../lib/Extensions")

const Buttons = styled.td`
  column-gap: 10px;
  display: grid;
  grid-template-columns: auto auto;
  row-gap: 10px;
`

export default class Places extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      merchant: null,
      places: [],
      menus: [],
      newPlace: false,
      editPlace: false,
      editPlaceMenus: false,
      place: null
    }

    this.reloadData = this.reloadData.bind(this)

    this.newPlace = this.newPlace.bind(this)
    this.cancelNewPlace = this.cancelNewPlace.bind(this)
    this.saveNewPlace = this.saveNewPlace.bind(this)

    this.editPlace = this.editPlace.bind(this)
    this.cancelEditPlace = this.cancelEditPlace.bind(this)
    this.saveEditPlace = this.saveEditPlace.bind(this)

    this.editPlaceMenus = this.editPlaceMenus.bind(this)
    this.cancelEditPlaceMenus = this.cancelEditPlaceMenus.bind(this)
    this.saveEditPlaceMenus = this.saveEditPlaceMenus.bind(this)
  }

  componentDidMount() {
    this.reloadData()
  }

  reloadData() {
    const getMerchant = Request.get("merchants/" + this.props.merchantId)
    const getPlaces = Request.get(
      "places?merchant_id=" + this.props.merchantId + "&include=menus"
    )
    const getMenus = Request.get("menus?merchant_id=" + this.props.merchantId)
    Promise.all([getMerchant, getPlaces, getMenus])
      .then((values) => {
        const [merchantRes, placesRes, menusRes] = values

        const merchant = merchantRes.data.result.merchant
        const places = placesRes.data.result.places
        const menus = menusRes.data.result.menus
        this.setState({ merchant: merchant, places: places, menus: menus })
      })
      .catch((err) => {
        console.log(err)
      })
  }

  newPlace() {
    this.setState({ newPlace: true })
  }
  cancelNewPlace() {
    this.setState({ newPlace: false })
  }
  saveNewPlace(data) {
    console.log(data)

    Request.post("places", data)
      .then((res) => {
        this.setState({ newPlace: false })
        this.reloadData()
      })
      .catch((err) => {
        alert(err.response ? err.response.data.context.message : err)
      })
  }

  editPlace(place) {
    this.setState({ editPlace: true, place: place })
  }
  cancelEditPlace() {
    this.setState({ editPlace: false })
  }
  saveEditPlace(data) {
    console.log(data)

    Request.post("places/" + data.id, data)
      .then((res) => {
        console.log(res)
        this.setState({ editPlace: false })
        this.reloadData()
      })
      .catch((err) => {
        alert(err.response ? err.response.data.context.message : err)
      })
  }

  viewFullMenu(place) {
    window.open(
      process.env.REACT_APP_BASE_URL + "/v1.0/places/" + place.id + "/full_menu"
    )
  }

  viewInvoices(place) {
    window.location.href = "/places/" + place.id + "/invoices"
  }

  editPlaceMenus(place) {
    console.log(place)
    this.setState({ editPlaceMenus: true, place: place })
  }
  cancelEditPlaceMenus() {
    this.setState({ editPlaceMenus: false })
  }
  saveEditPlaceMenus(data) {
    console.log(data)

    Request.post("places/" + data.placeId + "/attach_menus", {
      menuIds: data.menuIds
    })
      .then((res) => {
        console.log(res)
        this.setState({ editPlaceMenus: false })
        this.reloadData()
      })
      .catch((err) => {
        alert(err.response ? err.response.data.context.message : err)
      })
  }

  render() {
    return (
      <div>
        <Container fluid>
          <Row>
            <Col xs="8">
              <h1>
                Places for {this.state.merchant ? this.state.merchant.name : ""}
              </h1>
            </Col>
            <Col xs="4" className="d-flex align-items-center">
              <Button
                label="+ New Place"
                onClick={this.newPlace}
                outlined
                secondary
                small
              />
            </Col>
          </Row>
        </Container>
        <PlaceForm
          isOpen={this.state.newPlace}
          onCancel={this.cancelNewPlace}
          onSave={this.saveNewPlace}
          merchant={this.state.merchant}
        />
        <PlaceForm
          isOpen={this.state.editPlace}
          onCancel={this.cancelEditPlace}
          onSave={this.saveEditPlace}
          place={this.state.place}
          merchant={this.state.merchant}
        />
        <PlaceMenusForm
          isOpen={this.state.editPlaceMenus}
          onCancel={this.cancelEditPlaceMenus}
          onSave={this.saveEditPlaceMenus}
          place={this.state.place}
          menus={this.state.menus}
        />
        <Table striped bordered responsive>
          <thead>
            <tr>
              <th width="260">Actions</th>
              <th>Id</th>
              <th>Name</th>
              <th>Type</th>
              <th>Factual Place ID</th>
              <th>Emburse ID</th>
              <th>Stripe Customer ID</th>
              <th>Billing</th>
              <th>Menus</th>
            </tr>
          </thead>
          <tbody>
            {this.state.places.map((place) => (
              <tr>
                <Buttons>
                  <Button
                    onClick={() => this.editPlace(place)}
                    label="Edit"
                    outlined
                    secondary
                    small
                  />
                  <Button
                    onClick={() => this.viewFullMenu(place)}
                    label="Full Menu"
                    outlined
                    small
                  />
                  <Button
                    label="Invoices"
                    onClick={() => this.viewInvoices(place)}
                    outlined
                    small
                  />
                  <Button
                    label="Menus"
                    onClick={() => this.editPlaceMenus(place)}
                    outlined
                    small
                  />
                </Buttons>
                <td>{place.id}</td>
                <td>{place.name}</td>
                <td>{place.type}</td>
                <td>{place.factualPlaceId}</td>
                <td>{place.emburseMerchantId}</td>
                <td>
                  {place.stripeCustomerId ? (
                    <a
                      href={
                        "https://dashboard.stripe.com/customers/" +
                        place.stripeCustomerId
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {place.stripeCustomerId}
                    </a>
                  ) : (
                    ""
                  )}
                </td>
                <td>
                  {place.billingInterval &&
                  place.billingInterval > 0 &&
                  place.startBillingAt ? (
                    <Badge color="success">On</Badge>
                  ) : (
                    <Badge color="secondary">Off</Badge>
                  )}
                </td>
                <td>
                  <ul>
                    {place.placeMenus &&
                      place.placeMenus.map((placeMenu) => (
                        <li>{placeMenu.menu.name}</li>
                      ))}
                  </ul>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    )
  }
}
