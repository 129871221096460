import {
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  InputGroup,
  InputGroupAddon
} from "reactstrap"
import { Button } from "@fetch/ui"
import FormButtons from "./FormButtons"
import ImgSearch from "./MenuItemFormImgSearch"
import React from "react"
import Request from "../lib/Request"

export default class MenuItemForm extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isSaving: false,
      selectedImg: undefined
    }

    this.handleSubmit = this.handleSubmit.bind(this)
  }

  componentDidMount() {}

  handleSubmit(event) {
    this.setState({ isSaving: true })

    event.preventDefault()

    const formData = new FormData(event.target)

    var uploadImage = Promise.resolve()

    if (this.state.selectedImg) {
      Request.getCustomResponseType(
        `webimages/download?url=${this.state.selectedImg.url}`,
        "blob"
      )
        .then((response) => {
          const blob = response.data
          const imgname = `webimgdownload`
          const file = new File([blob], imgname, { type: blob.type })
          const uploadImageData = new FormData()
          uploadImageData.append("image", file)
          uploadImage = Request.post("images", uploadImageData).then((res) => {
            return res.data.result.image.id
          })
          uploadImage
            .then((imageId) => {
              var menuItemObject = {
                id: formData.get("id"),
                menuId: this.props.menu.id,
                name: formData.get("name"),
                detail: formData.get("detail")
              }

              const price = formData.get("price")
              menuItemObject["price"] = price
                ? Math.round(parseFloat(price) * 100)
                : null

              if (imageId) {
                menuItemObject.imageId = imageId
              }

              return this.props.onSave(menuItemObject)
            })
            .then(() => {
              this.setState({ isSaving: false })
            })
            .catch((err) => {
              alert(err.response ? err.response.data.context.message : err)
              this.setState({ isSaving: false })
            })
        })
        .catch((err) => {
          alert("Error uploading image")
          this.setState({ isSaving: false })
        })
    } else if (this.uploadInput.files[0]) {
      const uploadImageData = new FormData()

      uploadImageData.append("image", this.uploadInput.files[0])
      uploadImage = Request.post("images", uploadImageData).then((res) => {
        return res.data.result.image.id
      })
      uploadImage
        .then((imageId) => {
          var menuItemObject = {
            id: formData.get("id"),
            menuId: this.props.menu.id,
            name: formData.get("name"),
            detail: formData.get("detail")
          }

          const price = formData.get("price")
          menuItemObject["price"] = price
            ? Math.round(parseFloat(price) * 100)
            : null

          if (imageId) {
            menuItemObject.imageId = imageId
          }

          return this.props.onSave(menuItemObject)
        })
        .then(() => {
          this.setState({ isSaving: false })
        })
        .catch((err) => {
          alert(err.response ? err.response.data.context.message : err)
          this.setState({ isSaving: false })
        })
    } else {
      uploadImage
        .then((imageId) => {
          var menuItemObject = {
            id: formData.get("id"),
            menuId: this.props.menu.id,
            name: formData.get("name"),
            detail: formData.get("detail")
          }

          const price = formData.get("price")
          menuItemObject["price"] = price
            ? Math.round(parseFloat(price) * 100)
            : null

          if (imageId) {
            menuItemObject.imageId = imageId
          }
          document.getElementById("img-input").value = null
          this.setState({ selectedImg: undefined, imgFiles: undefined })
          return this.props.onSave(menuItemObject)
        })
        .then(() => {
          this.setState({ isSaving: false })
        })
        .catch((err) => {
          alert(err.response ? err.response.data.context.message : err)
          this.setState({ isSaving: false })
        })
    }
  }

  render() {
    return (
      <Modal
        isOpen={this.props.isOpen}
        onClosed={() => {
          document.getElementById("img-input").value = null
          this.setState({ selectedImg: undefined, imgFiles: undefined })
        }}
      >
        <Form onSubmit={this.handleSubmit}>
          <Input
            type="hidden"
            name="id"
            value={this.props.menuItem ? this.props.menuItem.id : ""}
          />
          <ModalHeader>
            {this.props.menuItem
              ? "Edit Item"
              : "New Item for " + (this.props.menu ? this.props.menu.name : "")}
          </ModalHeader>
          <ModalBody>
            <Row>
              <Col xs="8">
                <FormGroup>
                  <Label>
                    Name<sup>*</sup>
                  </Label>
                  <Input
                    type="text"
                    name="name"
                    defaultValue={
                      this.props.menuItem ? this.props.menuItem.name : ""
                    }
                  />
                </FormGroup>
              </Col>
              <Col xs="4">
                <FormGroup>
                  <Label>Price</Label>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">$</InputGroupAddon>
                    <Input
                      type="number"
                      step="0.01"
                      placeholder="0.00"
                      name="price"
                      defaultValue={
                        this.props.menuItem &&
                        typeof this.props.menuItem.price === "number"
                          ? this.props.menuItem.price / 100
                          : ""
                      }
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
            </Row>
            <FormGroup>
              <Label>Detail</Label>
              <Input
                type="text"
                name="detail"
                defaultValue={
                  this.props.menuItem ? this.props.menuItem.detail : ""
                }
              />
            </FormGroup>
            {this.props.menuItem &&
              this.props.menuItem.image &&
              this.props.menuItem.image.url && (
                <FormGroup>
                  <Label>Current Image</Label>
                  <Row>
                    <Col xs="12" sm="6">
                      <p>
                        <small>Full image</small>
                      </p>
                      <img
                        alt="previewcurrent"
                        src={this.props.menuItem.image.url}
                        className="img-thumbnail"
                        style={{ maxWidth: 200, maxHeight: 200 }}
                      />
                    </Col>
                    <Col xs="12" sm="6">
                      <p>
                        <small>Cropped for thumbnails</small>
                      </p>
                      <div
                        className="img-thumbnail ratio-4-3"
                        style={{
                          backgroundImage: `url(${this.props.menuItem.image.url})`,
                          maxWidth: 200,
                          maxHeight: 200
                        }}
                      ></div>
                    </Col>
                  </Row>
                </FormGroup>
              )}
            <FormGroup>
              <Label>Upload new image</Label>
              <Input
                id="img-input"
                type="file"
                name="image"
                innerRef={(ref) => {
                  this.uploadInput = ref
                }}
                onChange={(e) =>
                  this.setState({
                    selectedImg: undefined,
                    imgFiles: e.target.files
                  })
                }
              />
              {this.state.imgFiles && (
                <React.Fragment>
                  <p style={{ marginTop: 10 }}>New image preview</p>
                  <Row>
                    <Col xs="12" sm="6">
                      <p>
                        <small>Full image</small>
                      </p>
                      <img
                        alt="previewup"
                        src={URL.createObjectURL(this.state.imgFiles[0])}
                        className="img-thumbnail"
                        style={{ maxWidth: 200, maxHeight: 200 }}
                      />
                    </Col>
                    <Col xs="12" sm="6">
                      <p>
                        <small>Cropped for thumbnails</small>
                      </p>
                      <div
                        className="img-thumbnail ratio-4-3"
                        style={{
                          backgroundImage: `url(${URL.createObjectURL(
                            this.state.imgFiles[0]
                          )})`,
                          maxWidth: 200,
                          maxHeight: 200
                        }}
                      ></div>
                    </Col>
                  </Row>
                  <div
                    style={{
                      marginTop: 10,
                      marginBottom: 15,
                      textAlign: "right"
                    }}
                  >
                    <Button
                      label="Remove"
                      onClick={() => {
                        document.getElementById("img-input").value = null
                        this.setState({ imgFiles: undefined })
                      }}
                      secondary
                      small
                    />
                  </div>
                </React.Fragment>
              )}
            </FormGroup>
            <ImgSearch
              onImgSelected={(img) => {
                document.getElementById("img-input").value = null
                this.setState({ selectedImg: img })
              }}
              selectedImg={this.state.selectedImg}
              onImgDeselected={() => this.setState({ selectedImg: undefined })}
            />
          </ModalBody>
          <ModalFooter>
            <FormButtons
              isSaving={this.state.isSaving}
              onCancel={this.props.onCancel}
            >
              {this.props.menuItem ? "Save" : "Create Item"}
            </FormButtons>
          </ModalFooter>
        </Form>
      </Modal>
    )
  }
}
