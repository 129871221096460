// @flow
/* eslint-disable no-ternary */

import "./App.css"
import Navigation from "./components/Navigation"
import React from "react"
import { BrowserRouter as Router } from "react-router-dom"
import Routes from "./Routes"

const App = () => {
  const currentUserString = sessionStorage.getItem("currentUser")
  const currentUser = currentUserString ? JSON.parse(currentUserString) : null

  return (
    <Router>
      <>
        {currentUser ? <Navigation currentUser={currentUser} /> : ""}
        <Routes currentUser={currentUser} />
      </>
    </Router>
  )
}

export default App
