import React from 'react';
import { Container, Row, Col, Table } from 'reactstrap';

import Request from '../lib/Request';
import LoadingIndicator from './LoadingIndicator';
import SearchBar from './SearchBar';
import Moment from 'react-moment';

require('../lib/Extensions')

export default class EmburseMerchantSearch extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      emburseMerchants: [],
      query: null
    };

    this.loadData = this.loadData.bind(this);
    this.search = this.search.bind(this);
  }

  componentDidMount() {
  }

  loadData(query) {
    this.setState({ isLoading: true })

    Request.get(`emburse/merchant_search?query=${query || ''}`).then(res => {
      this.setState({ isLoading: false, emburseMerchants: res.data.result.emburseMerchants });
    }).catch(err => {
      alert(err.response ? err.response.data.context.message : err)
      this.setState({ isLoading: false })
    })
  }

  search(searchTerm) {
    this.loadData(searchTerm.trim())
  }

  render() {
    return (
      <div>
        <Container fluid>
          <Row>
            <Col xs="9"><h1>Emburse Merchant Search {this.state.query && 'for "' + this.state.query + '"'}<LoadingIndicator isLoading={this.state.isLoading} /></h1></Col>
            <Col xs="3" className="d-flex align-items-center"><SearchBar onSearch={this.search} query={this.state.query} onClear={() => this.loadData('')}/></Col>
          </Row>
        </Container>
        <Table striped bordered responsive>
          <thead>
            <tr><th width="80">Emburse Id</th><th>Name</th><th>Date of Purchase</th></tr>
          </thead>
          <tbody>
            { this.state.emburseMerchants.map(emburseMerchant => <tr>
              <td>{emburseMerchant.emburseMerchantId}</td>
              <td>{emburseMerchant.emburseMerchantName}</td>
              <td><Moment format="YYYY/MM/DD HH:mm">{emburseMerchant.emburseDate}</Moment></td>
            </tr>)}
          </tbody>
        </Table>
      </div>
    )
  }
}
