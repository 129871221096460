/* eslint-disable max-statements, react/require-optimization, vars-on-top, no-var, init-declarations, no-magic-numbers, react/destructuring-assignment, prefer-destructuring, camelcase, no-dupe-else-if, object-shorthand, react/no-set-state, no-alert, no-ternary, lines-between-class-members, prefer-template, max-lines-per-function, react/jsx-handler-names, no-nested-ternary, react/jsx-no-bind, max-lines, react/no-access-state-in-setstate, class-methods-use-this, react/jsx-max-depth, no-param-reassign, id-length */

import { Col, Container, Row, Table } from "reactstrap"
import { Button } from "@fetch/ui"
import CouponForm from "./CouponForm"
import Moment from "react-moment"
import QueryString from "querystring"
import React from "react"
import Request from "../lib/Request"
import styled from "styled-components"

require("../lib/Extensions")

const Buttons = styled.td`
  column-gap: 10px;
  display: grid;
  grid-template-columns: auto auto;
`

export default class Coupons extends React.Component {
  constructor(props) {
    super(props)

    const queryString = this.props.location.search.slice(1)
    var offset
    if (queryString) {
      offset = QueryString.parse(queryString).offset
    }

    this.state = {
      coupon: null,
      coupons: [],
      editCoupon: false,
      newCoupon: false,
      offset: offset || 0
    }

    this.reloadData = this.reloadData.bind(this)

    this.newCoupon = this.newCoupon.bind(this)
    this.cancelNewCoupon = this.cancelNewCoupon.bind(this)
    this.saveNewCoupon = this.saveNewCoupon.bind(this)

    this.editCoupon = this.editCoupon.bind(this)
    this.cancelEditCoupon = this.cancelEditCoupon.bind(this)
    this.saveEditCoupon = this.saveEditCoupon.bind(this)
  }

  componentDidMount() {
    this.reloadData()
  }

  reloadData() {
    Request.get(`coupons?include=places&limit=1000&offset=${this.state.offset}`)
      .then((res) => {
        const coupons = res.data.result.coupons
        this.setState({ coupons: coupons })
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.log(err)
      })
  }

  newCoupon() {
    this.setState({ newCoupon: true })
  }

  cancelNewCoupon() {
    this.setState({ newCoupon: false })
  }

  saveNewCoupon(data) {
    Request.post("coupons", data)
      .then(() => {
        this.setState({ newCoupon: false })
        this.reloadData()
      })
      .catch((err) => {
        alert(err.response ? err.response.data.context.message : err)
      })
  }

  editCoupon(coupon) {
    this.setState({ coupon: coupon, editCoupon: true })
  }

  cancelEditCoupon() {
    this.setState({ editCoupon: false })
  }

  saveEditCoupon(data) {
    Request.post("coupons/" + data.id, data)
      .then(() => {
        this.setState({ editCoupon: false })
        this.reloadData()
      })
      .catch((err) => {
        alert(err.response ? err.response.data.context.message : err)
      })
  }

  deleteCoupon(coupon) {
    if (window.confirm("Are you sure you want to delete this coupon?")) {
      Request.post("coupons/" + coupon.id + "/delete")
        .then(() => {
          this.reloadData()
        })
        .catch((err) => {
          alert(err.response ? err.response.data.context.message : err)
        })
    }
  }

  render() {
    return (
      <div>
        <Container fluid>
          <Row>
            <Col xs="10">
              <h1>{"Coupons"}</h1>
            </Col>
            <Col xs="2">
              <Button
                label="+ New Coupon"
                onClick={this.newCoupon}
                outlined
                secondary
                small
              />
            </Col>
          </Row>
        </Container>
        <CouponForm
          cancel={this.cancelNewCoupon}
          isOpen={this.state.newCoupon}
          save={this.saveNewCoupon}
        />
        <CouponForm
          cancel={this.cancelEditCoupon}
          coupon={this.state.coupon}
          isOpen={this.state.editCoupon}
          save={this.saveEditCoupon}
        />
        <Table bordered responsive striped>
          <thead>
            <tr>
              <th>{"Actions"}</th>
              <th>{"Id"}</th>
              <th>{"Type"}</th>
              <th>{"Code"}</th>
              <th>{"Discount"}</th>
              <th>{"Expiration Date"}</th>
              <th>{"Min. Purchase"}</th>
              <th>{"Places"}</th>
            </tr>
          </thead>
          <tbody>
            {this.state.coupons.map((coupon) => (
              <tr key={coupon.id}>
                <Buttons>
                  <Button
                    label="Edit"
                    onClick={() => this.editCoupon(coupon)}
                    outlined
                    secondary
                    small
                  />

                  <Button
                    label="Delete"
                    onClick={() => this.deleteCoupon(coupon)}
                    secondary
                    small
                  />
                </Buttons>
                <td>{coupon.id}</td>
                <td>{coupon.type}</td>
                <td>{coupon.code}</td>
                <td>
                  {coupon.discountAmount
                    ? coupon.discountAmount.formatDollars()
                    : null}
                </td>
                <td>
                  {coupon.expirationDate ? (
                    <Moment format="YYYY/MM/DD HH:mm">
                      {coupon.expirationDate}
                    </Moment>
                  ) : (
                    ""
                  )}
                </td>
                <td>
                  {coupon.minPurchaseAmount
                    ? coupon.minPurchaseAmount.formatDollars()
                    : null}
                </td>
                <td>
                  {coupon.couponPlaces
                    ? coupon.couponPlaces
                        .map((couponPlace) => couponPlace.place.name)
                        .join(", ")
                    : ""}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    )
  }
}
