/* eslint-disable react/require-optimization, class-methods-use-this, no-empty-function, max-statements, max-lines-per-function, vars-on-top, no-var,react/no-set-state, dot-notation, no-ternary, no-magic-numbers, react/destructuring-assignment, no-alert, complexity, react/jsx-no-literals, react/jsx-max-depth, max-lines, prefer-template, react/jsx-handler-names */

import {
  Col,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row
} from "reactstrap"
import FormButtons from "./FormButtons"
import React from "react"
import moment from "moment"

export default class CouponForm extends React.Component {
  constructor(props) {
    super(props)

    this.handleSubmit = this.handleSubmit.bind(this)
  }

  componentDidMount() {}

  handleSubmit(event) {
    event.preventDefault()
    const formData = new FormData(event.target)

    var couponObject = {
      code: formData.get("code"),
      detail: formData.get("detail"),
      id: formData.get("id"),
      type: formData.get("type")
    }

    const discountAmount = formData.get("discountAmount")
    couponObject["discountAmount"] = discountAmount
      ? Math.round(parseFloat(discountAmount) * 100)
      : null

    const minPurchaseAmount = formData.get("minPurchaseAmount")
    couponObject["minPurchaseAmount"] = minPurchaseAmount
      ? Math.round(parseFloat(minPurchaseAmount) * 100)
      : null

    const reimbursementAmount = formData.get("reimbursementAmount")
    couponObject["reimbursementAmount"] = reimbursementAmount
      ? Math.round(parseFloat(reimbursementAmount) * 100)
      : null

    const expirationDate = formData.get("expirationDate")
    const expirationTime = formData.get("expirationTime")

    if (expirationDate && expirationTime) {
      const expirationDateString = moment(expirationDate + " " + expirationTime)
        .utcOffset(0)
        .format("YYYY-MM-DD HH:mm")
      couponObject["expirationDate"] = expirationDateString
    } else {
      couponObject["expirationDate"] = null
    }

    const placeIds = formData.get("place_ids")
    if (placeIds) {
      couponObject["placeIds"] = placeIds.split(",")
    }

    this.props.save(couponObject)
  }

  render() {
    return (
      <Modal isOpen={this.props.isOpen}>
        <Form onSubmit={this.handleSubmit}>
          <Input
            name="id"
            type="hidden"
            value={this.props.coupon ? this.props.coupon.id : ""}
          />
          <ModalHeader>
            {this.props.coupon ? "Edit Coupon" : "New Coupon"}
          </ModalHeader>
          <ModalBody>
            <FormGroup>
              <Row>
                <Col xs="6">
                  <Label>Type</Label>
                  <Input
                    defaultValue={
                      this.props.coupon ? this.props.coupon.type : ""
                    }
                    disabled={this.props.coupon ? "disabled" : ""}
                    name="type"
                    type="select"
                  >
                    <option value="default">Default</option>
                    <option value="firstFetch">First Order</option>
                    <option value="multipleStops">Multiple Stops</option>
                    <option value="oneTime">One Time</option>
                    <option value="multiUse">Multi-Use</option>
                  </Input>
                </Col>
                <Col xs="6">
                  <FormGroup>
                    <Label>Code</Label>
                    <Input
                      defaultValue={
                        this.props.coupon ? this.props.coupon.code : ""
                      }
                      name="code"
                      type="text"
                    />
                  </FormGroup>
                </Col>
              </Row>
            </FormGroup>
            <Row>
              <Col xs="4">
                <FormGroup>
                  <Label>Discount</Label>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">$</InputGroupAddon>
                    <Input
                      defaultValue={
                        this.props.coupon && this.props.coupon.discountAmount
                          ? this.props.coupon.discountAmount / 100
                          : ""
                      }
                      name="discountAmount"
                      placeholder="0.00"
                      step="0.01"
                      type="number"
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col xs="4">
                <FormGroup>
                  <Label>Min. Purchase</Label>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">$</InputGroupAddon>
                    <Input
                      defaultValue={
                        this.props.coupon && this.props.coupon.minPurchaseAmount
                          ? this.props.coupon.minPurchaseAmount / 100
                          : ""
                      }
                      name="minPurchaseAmount"
                      placeholder="0.00"
                      step="0.01"
                      type="number"
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col xs="4">
                <FormGroup>
                  <Label>Reimbursement</Label>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">$</InputGroupAddon>
                    <Input
                      defaultValue={
                        this.props.coupon &&
                        this.props.coupon.reimbursementAmount
                          ? this.props.coupon.reimbursementAmount / 100
                          : ""
                      }
                      name="reimbursementAmount"
                      placeholder="0.00"
                      step="0.01"
                      type="number"
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
            </Row>
            <FormGroup>
              <Label>Detail</Label>
              <Input
                defaultValue={this.props.coupon ? this.props.coupon.detail : ""}
                name="detail"
                type="text"
              />
            </FormGroup>
            <FormGroup>
              <Label>Expiration Date and Time</Label>
              <Row>
                <Col xs="6">
                  <Input
                    defaultValue={
                      this.props.coupon && this.props.coupon.expirationDate
                        ? moment(this.props.coupon.expirationDate).format(
                            "YYYY-MM-DD"
                          )
                        : ""
                    }
                    name="expirationDate"
                    type="date"
                  />
                </Col>
                <Col xs="6">
                  <Input
                    defaultValue={
                      this.props.coupon && this.props.coupon.expirationDate
                        ? moment(this.props.coupon.expirationDate).format(
                            "HH:mm"
                          )
                        : ""
                    }
                    name="expirationTime"
                    type="time"
                  />
                </Col>
              </Row>
            </FormGroup>
            <FormGroup>
              <Label>Place IDs</Label>
              <Input
                defaultValue={
                  this.props.coupon && this.props.coupon.couponPlaces
                    ? this.props.coupon.couponPlaces
                        .map((couponPlace) => couponPlace.place.id)
                        .join(",")
                    : ""
                }
                name="place_ids"
                type="text"
              />
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <FormButtons isSaving={false} onCancel={this.props.cancel}>
              {this.props.coupon ? "Save" : "Create Coupon"}
            </FormButtons>
          </ModalFooter>
        </Form>
      </Modal>
    )
  }
}
