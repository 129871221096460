/* eslint-disable max-statements, react/require-optimization, vars-on-top, no-var, init-declarations, no-magic-numbers, react/destructuring-assignment, prefer-destructuring, camelcase, no-dupe-else-if, object-shorthand, react/no-set-state, no-alert, no-ternary, lines-between-class-members, prefer-template, max-lines-per-function, react/jsx-handler-names, no-nested-ternary, react/jsx-no-bind, max-lines, react/no-access-state-in-setstate, class-methods-use-this, react/jsx-max-depth, no-param-reassign, id-length */

import {
  Col,
  Container,
  Input,
  InputGroup,
  InputGroupAddon,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table
} from "reactstrap"
import { Button } from "@fetch/ui"
import DriverForm from "./DriverForm"
import LoadingIndicator from "./LoadingIndicator"
import React from "react"
import Request from "../lib/Request"
import SearchBar from "./SearchBar"
import UserManager from "../lib/UserManager"
import handleError from "../lib/ErrorHandler"
import styled from "styled-components"

require("../lib/Extensions")

const Buttons = styled.td`
  column-gap: 10px;
  display: grid;
  grid-template-columns: auto auto auto;
`

const NewDriverButtonWrapper = styled.div`
  margin-top: 10px;
`

export default class Drivers extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      driver: null,
      drivers: [],
      editDriver: false,
      isLoading: false,
      newDriver: false,
      offset: 0,
      pageSize: 10,
      query: null
    }

    this.loadData = this.loadData.bind(this)
    this.reloadData = this.reloadData.bind(this)
    this.search = this.search.bind(this)

    this.newDriver = this.newDriver.bind(this)
    this.cancelNewDriver = this.cancelNewDriver.bind(this)
    this.saveNewDriver = this.saveNewDriver.bind(this)

    this.editDriver = this.editDriver.bind(this)
    this.cancelEditDriver = this.cancelEditDriver.bind(this)
    this.saveEditDriver = this.saveEditDriver.bind(this)
  }

  componentDidMount() {
    this.reloadData()
  }

  loadData(offset, query) {
    this.setState({ isLoading: true })

    if (!offset) {
      offset = 0
    }

    return UserManager.getDrivers(offset, this.state.pageSize, query)
      .then((drivers) => {
        this.setState({
          drivers: drivers,
          isLoading: false,
          offset: offset || 0,
          query: query
        })
      })
      .catch((err) => {
        handleError(err)
        this.setState({ isLoading: false })
      })
  }

  reloadData() {
    this.loadData(this.state.offset, this.state.query)
  }

  search(searchTerm) {
    this.loadData(0, searchTerm.trim())
  }

  nextPage() {
    this.loadData(this.state.offset + this.state.pageSize, this.state.query)
  }

  previousPage() {
    const offset = Math.max(this.state.offset - this.state.pageSize, 0)
    this.loadData(offset, this.state.query)
  }

  changePageSize(newPageSize) {
    this.setState({ pageSize: newPageSize })
    this.loadData(0, this.state.query)
  }

  newDriver() {
    this.setState({ newDriver: true })
  }

  cancelNewDriver() {
    this.setState({ newDriver: false })
  }

  saveNewDriver(data) {
    Request.post("drivers", data)
      .then(() => {
        this.setState({ newDriver: false })
        this.reloadData()
      })
      .catch((err) => {
        alert(err.response ? err.response.data.context.message : err)
      })
  }

  editDriver(driver) {
    this.setState({ driver: driver, editDriver: true })
  }
  cancelEditDriver() {
    this.setState({ editDriver: false })
  }
  saveEditDriver(data) {
    var userObject = {
      accountIdentifier: data.accountIdentifier,
      email: data.email,
      firstName: data.firstName,
      id: data.id,
      lastName: data.lastName,
      phoneNumber: data.phoneNumber,
      photoId: data.imageId
    }

    Request.post("users/" + userObject.id, userObject)
      .then(() => {
        this.setState({ editDriver: false })
        this.reloadData()
      })
      .catch((err) => {
        alert(err.response ? err.response.data.context.message : err)
      })
  }

  makeDriverUnavailable(driver) {
    if (
      window.confirm(
        `Are you sure you want to make ${driver.user.firstName} unavailable?`
      )
    ) {
      var driverObject = {
        id: driver.id,
        status: "unavailable"
      }
      Request.post("drivers/" + driver.id, driverObject)
        .then(() => {
          this.loadData(this.state.offset, this.state.query)
        })
        .catch((err) => {
          handleError(err)
        })
    }
  }

  makeDriverAvailable(driver) {
    if (
      window.confirm(
        `Are you sure you want to make ${driver.user.firstName} available?`
      )
    ) {
      var driverObject = {
        id: driver.id,
        status: "available"
      }
      Request.post("drivers/" + driver.id, driverObject)
        .then(() => {
          this.loadData(this.state.offset, this.state.query)
        })
        .catch((err) => {
          handleError(err)
        })
    }
  }

  archiveDriver(driver) {
    if (
      window.confirm(
        `Are you sure you want to archive ${driver.user.firstName}?`
      )
    ) {
      Request.post("drivers/" + driver.id + "/archive")
        .then(() => {
          this.loadData(this.state.offset, this.state.query)
        })
        .catch((err) => {
          handleError(err)
        })
    }
  }

  render() {
    return (
      <div>
        <DriverForm
          isOpen={this.state.newDriver}
          onCancel={this.cancelNewDriver}
          onSave={this.saveNewDriver}
        />
        <DriverForm
          driverUser={this.state.driver ? this.state.driver.user : null}
          isOpen={this.state.editDriver}
          onCancel={this.cancelEditDriver}
          onSave={this.saveEditDriver}
        />
        <Container fluid>
          <Row>
            <Col xs="3">
              <h1>
                {"Drivers "}
                {this.state.query && 'for "' + this.state.query + '"'}
                <LoadingIndicator isLoading={this.state.isLoading} />
              </h1>
            </Col>
            <Col className="d-flex align-items-center" xs="3">
              <SearchBar
                onClear={() => this.loadData(0, "")}
                onSearch={this.search}
                query={this.state.query}
              />
            </Col>
            <Col className="d-flex align-items-center" xs="4">
              <Pagination>
                <PaginationItem disabled={this.state.offset === 0}>
                  <PaginationLink
                    href="#"
                    onClick={() => this.previousPage()}
                    previous
                  />
                </PaginationItem>
                <PaginationItem>
                  <PaginationLink>
                    {Math.round(this.state.offset / this.state.pageSize) + 1}
                  </PaginationLink>
                </PaginationItem>
                <PaginationItem
                  disabled={this.state.drivers.length < this.state.pageSize}
                >
                  <PaginationLink
                    href="#"
                    next
                    onClick={() => this.nextPage()}
                  />
                </PaginationItem>
              </Pagination>
              &nbsp;
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  {"Page Size: "}
                </InputGroupAddon>
                <Input
                  name="pageSize"
                  onChange={(e) => this.changePageSize(`${e.target.value}`)}
                  type="select"
                >
                  <option>{"10"}</option>
                  <option>{"50"}</option>
                  <option>{"200"}</option>
                  <option>{"1000"}</option>
                </Input>
              </InputGroup>
            </Col>
            <Col xs="2">
              <NewDriverButtonWrapper>
                <Button
                  label="+ New Driver"
                  onClick={this.newDriver}
                  outlined
                  secondary
                  small
                />
              </NewDriverButtonWrapper>
            </Col>
          </Row>
        </Container>
        <Table bordered responsive striped>
          <thead>
            <tr>
              <th width="500">{"Actions"}</th>
              <th width="80">{"User Id"}</th>
              <th>{"Name"}</th>
              <th>{"Email"}</th>
              <th>{"Phone"}</th>
              <th>{"accountIdentifier"}</th>
            </tr>
          </thead>
          <tbody>
            {this.state.drivers.map((driver) => (
              <tr key={driver.id}>
                <Buttons>
                  <Button
                    label="Edit"
                    onClick={() => this.editDriver(driver)}
                    outlined
                    secondary
                    small
                  />
                  {driver.status === "unavailable" ? (
                    <Button
                      label="Make Available"
                      onClick={() => this.makeDriverAvailable(driver)}
                      outlined
                      small
                    />
                  ) : (
                    <Button
                      label="Make Unavailable"
                      onClick={() => this.makeDriverUnavailable(driver)}
                      outlined
                      small
                    />
                  )}
                  <Button
                    label="Archive"
                    onClick={() => this.archiveDriver(driver)}
                    outlined
                    small
                  />
                </Buttons>
                <td>{driver.user.id}</td>
                <td>{driver.user.firstName + " " + driver.user.lastName}</td>
                <td>{driver.user.email}</td>
                <td>{driver.user.phoneNumber}</td>
                <td>{driver.user.accountIdentifier}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    )
  }
}
