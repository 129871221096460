// @flow
/* eslint-disable max-statements, max-lines-per-function, react/jsx-no-bind */

import {
  Collapse,
  Nav,
  NavItem,
  NavLink,
  Navbar,
  NavbarBrand,
  NavbarToggler
} from "reactstrap"
import { Button } from "@fetch/ui"
import Constants from "../lib/Constants"
import React from "react"
import { useGoogleLogout } from "react-google-login"
import { useToggle } from "@bluframe/grapple"

const DEFAULT_IS_COLLAPSED = true

type Props = {|
  +currentUser: any
|}

const Navigation = ({ currentUser }: Props) => {
  const [isCollapsed, toggleIsCollapsed] = useToggle(DEFAULT_IS_COLLAPSED)

  const onToggleNavbar = () => {
    toggleIsCollapsed()
  }

  const onLogoutSuccess = () => {
    sessionStorage.removeItem("currentUser")
    window.location.href = "/"
  }

  const { signOut } = useGoogleLogout({
    clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
    onLogoutSuccess
  })

  const onLogout = () => {
    signOut()
  }

  if (!currentUser) {
    return (
      <Navbar className="bg-dark" dark expand="md">
        <NavbarBrand href="/">{process.env.REACT_APP_PROJECT_NAME}</NavbarBrand>
      </Navbar>
    )
  }

  if (currentUser.user.type === Constants.UserType.driver) {
    return (
      <Navbar className="bg-dark" dark expand="md">
        <NavbarBrand href="/">
          {process.env.REACT_APP_PROJECT_NAME}
          {" - "}
          {currentUser.user.firstName} {currentUser.user.lastName}
        </NavbarBrand>
        <NavbarToggler className="mr-2" onClick={onToggleNavbar} />
        <Collapse isOpen={!isCollapsed} navbar>
          <Nav className="ml-auto" navbar>
            <NavItem>
              <NavLink href="/">{"Dashboard"}</NavLink>
            </NavItem>
            <NavItem>
              <Button
                label="Logout"
                onClick={onLogout}
                outlined
                secondary
                small
              />
            </NavItem>
          </Nav>
        </Collapse>
      </Navbar>
    )
  } else if (currentUser.user.type === Constants.UserType.admin) {
    return (
      <Navbar className="bg-dark" dark expand="md">
        <NavbarBrand href="/">
          {process.env.REACT_APP_PROJECT_NAME} {" - "}
          {currentUser.user.firstName} {currentUser.user.lastName}
        </NavbarBrand>
        <NavbarToggler className="mr-2" onClick={onToggleNavbar} />
        <Collapse isOpen={!isCollapsed} navbar>
          <Nav className="ml-auto" navbar>
            <NavItem>
              <NavLink href="/">{"Dashboard"}</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="/orders">{"Orders"}</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="/customers">{"Customers"}</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="/drivers">{"Drivers"}</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="/merchants">{"Merchants"}</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="/coupons">{"Coupons"}</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="/admins">{"Admins"}</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="/regions">{"Regions"}</NavLink>
            </NavItem>
            <NavItem>
              <Button
                label="Logout"
                onClick={onLogout}
                outlined
                secondary
                small
              />
            </NavItem>
          </Nav>
        </Collapse>
      </Navbar>
    )
  }

  return <div>{"Invalid User"}</div>
}

export default Navigation
