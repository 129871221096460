/* eslint-disable max-statements, init-declarations, no-magic-numbers, react/destructuring-assignment, prefer-destructuring, camelcase, no-dupe-else-if, object-shorthand, react/no-set-state, no-alert, no-ternary, lines-between-class-members, prefer-template, max-lines-per-function, react/jsx-handler-names, no-nested-ternary, react/jsx-no-bind, max-lines, react/no-unused-prop-types, max-classes-per-file, no-warning-comments, react/require-optimization, react/no-multi-comp, react/no-array-index-key */

import { Col, Collapse, Container, Row, Table } from "reactstrap"
import Badge from "@fetch/ui/Badge"
import Button from "@fetch/ui/Button"
import FetchData from "./FetchData"
import { Link } from "react-router-dom"
import React from "react"
import Request from "../lib/Request"
import User from "./User"

// TODO: consolidate showing user and fetch with Fetches.js

const minutesFromDate = (date) =>
  Math.round((new Date() - new Date(date)) / (60 * 1000))

export default class ActiveFetches extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      fetch: null,
      fetchData: null,
      fetches: [],
      lastUpdatedAt: null,
      reload: true,
      user: null
    }

    this.reloadData = this.reloadData.bind(this)

    this.showUser = this.showUser.bind(this)
    this.closeUser = this.closeUser.bind(this)

    this.showFetch = this.showFetch.bind(this)
    this.closeFetch = this.closeFetch.bind(this)
  }

  componentDidMount() {
    this.reloadData()
    setInterval(this.reloadData, 5000)
  }

  reloadData() {
    if (!this.state.reload) {
      return
    }

    Request.get(
      "fetches?include=customer,driver,stops,items&state=pending,accepted,arrivedAtStop,finishedWithStop,completionPendingForDriver&limit=250&region_id=" +
        this.props.regionId
    )
      .then((res) => {
        const fetches = res.data.result.fetches
        this.setState({ fetches: fetches, lastUpdatedAt: new Date() })
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.log(err)
        this.setState({ reload: false })
      })
  }

  showUser(user) {
    this.setState({ user: user })
  }
  closeUser() {
    this.setState({ user: null })
  }

  showFetch(fetch) {
    Request.get("fetches/" + fetch.id + "/text")
      .then((res) => {
        this.setState({ fetch: fetch, fetchData: res.data })
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.log(err)
      })
  }
  closeFetch() {
    this.setState({ fetch: null, fetchData: null })
  }

  render() {
    return (
      <div>
        <Container fluid>
          <Row>
            <Col xs="8">
              <h1>{"Active Orders"}</h1>
            </Col>
            <Col className="d-flex align-items-center" xs="4">
              {"Last Updated: "}
              {this.state.lastUpdatedAt
                ? this.state.lastUpdatedAt.toLocaleString()
                : "never"}
            </Col>
          </Row>
        </Container>
        <User
          close={this.closeUser}
          isOpen={Boolean(this.state.user)}
          user={this.state.user}
        />
        <FetchData
          close={this.closeFetch}
          data={this.state.fetchData}
          fetch={this.state.fetch}
          isOpen={Boolean(this.state.fetch)}
        />
        <Table bordered hover striped>
          <thead>
            <tr>
              <th>{"Id"}</th>
              <th>{"Age (Minutes)"}</th>
              <th>{"State"}</th>
              <th>{"Customer"}</th>
              <th>{"Driver"}</th>
              <th>{"Stops"}</th>
            </tr>
          </thead>
          <tbody>
            {this.state.fetches.map((fetch) => (
              <tr
                className={
                  (fetch.state === "pending" &&
                    minutesFromDate(fetch.createdAt) > 3) ||
                  minutesFromDate(fetch.createdAt) > 60
                    ? "bg-warning"
                    : ""
                }
                key={fetch.id}
              >
                <td>
                  <Link onClick={() => this.showFetch(fetch)} to="#">
                    {fetch.id}
                  </Link>
                </td>
                <td>{minutesFromDate(fetch.createdAt)}</td>
                <td>{fetch.state}</td>
                <td
                  style={{
                    display: "grid",
                    justifyItems: "start",
                    rowGap: "4px"
                  }}
                >
                  <Link
                    onClick={() =>
                      this.showUser(fetch.customer ? fetch.customer.user : null)
                    }
                    to="#"
                  >
                    {fetch.customer?.user
                      ? fetch.customer.user.firstName +
                        " " +
                        fetch.customer.user.lastName
                      : ""}
                  </Link>
                  {fetch.customer?.user?.hasSubscription ? (
                    <Badge color="secondary" label="Fetch Unlimited" />
                  ) : null}
                </td>
                <td>
                  <Link
                    onClick={() =>
                      this.showUser(fetch.driver ? fetch.driver.user : null)
                    }
                    to="#"
                  >
                    {fetch.driver?.user
                      ? fetch.driver.user.firstName +
                        " " +
                        fetch.driver.user.lastName
                      : ""}
                  </Link>
                </td>
                <td>
                  <CollapsibleStops stops={fetch.stops} />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    )
  }
}

class CollapsibleStops extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showStops: false
    }
    this.toggleStops = this.toggleStops.bind(this)
  }

  toggleStops() {
    this.setState((previousState) => ({ showStops: !previousState.showStops }))
  }

  render() {
    if (this.props.stops.length === 0) return <span>{"No stops"}</span>
    return (
      <>
        <Button
          label={this.state.showStops ? "Hide" : "Show"}
          onClick={this.toggleStops}
          outlined
          small
        />

        <Collapse isOpen={this.state.showStops}>
          <ul>
            {this.props.stops.map((stop, index) => (
              <li key={index}>{stop.prettyDescription}</li>
            ))}
          </ul>
        </Collapse>
      </>
    )
  }
}
