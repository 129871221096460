import {
  Button,
  InputGroup,
  InputGroupAddon,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input
} from "reactstrap"
import FormButtons from "./FormButtons"
import React from "react"

export default class RefundFetch extends React.Component {
  constructor(props) {
    super(props)

    this.handleSubmit = this.handleSubmit.bind(this)
  }

  componentDidMount() {}

  handleSubmit(event) {
    event.preventDefault()
    const formData = new FormData(event.target)

    var refundObject = {
      fetchId: formData.get("fetchId"),
      reason: formData.get("reason")
    }

    const amount = formData.get("amount")
    refundObject["amount"] = amount
      ? Math.round(parseFloat(amount) * 100)
      : null

    this.props.onSubmit(refundObject)
  }

  render() {
    return (
      <Modal isOpen={this.props.isOpen}>
        <Form onSubmit={this.handleSubmit}>
          <Input
            type="hidden"
            name="fetchId"
            value={this.props.fetch ? this.props.fetch.id : ""}
          />
          <ModalHeader>
            Refund Order #{this.props.fetch ? this.props.fetch.id : ""}
          </ModalHeader>
          <ModalBody>
            <FormGroup>
              <Label>Amount</Label>
              <InputGroup>
                <InputGroupAddon addonType="prepend">$</InputGroupAddon>
                <Input
                  type="number"
                  step="0.01"
                  placeholder="0.00"
                  name="amount"
                />
              </InputGroup>
            </FormGroup>
            <FormGroup>
              <Label>Reason</Label>
              <Input type="text" name="reason" placeholder="missing item" />
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <FormButtons isSaving={false} onCancel={this.props.onCancel}>
              {"Submit"}
            </FormButtons>
          </ModalFooter>
        </Form>
      </Modal>
    )
  }
}
