import React from "react"
import { Jumbotron, Alert, Spinner } from "reactstrap"
import GoogleLogin from "react-google-login"
import axios from "axios"

import Constants from "../lib/Constants"

export default class Login extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isLoggingIn: false,
      error: null
    }

    this.googleDriverSuccess = this.googleDriverSuccess.bind(this)
    this.googleAdminSuccess = this.googleAdminSuccess.bind(this)
    this.googleFail = this.googleFail.bind(this)
  }

  googleDriverSuccess(response) {
    this.loginSuccess(response.tokenId, Constants.UserType.driver)
  }

  googleAdminSuccess(response) {
    this.loginSuccess(response.tokenId, Constants.UserType.admin)
  }

  loginSuccess(tokenId, userType) {
    this.setState({ isLoggingIn: true })
    axios
      .post(process.env.REACT_APP_BASE_URL + "/v1.0/users/login", {
        userType: userType,
        provider: "google",
        providerAuthToken: tokenId
      })
      .then((res) => {
        console.log(res.data.result)
        sessionStorage.setItem("currentUser", JSON.stringify(res.data.result))
        window.location.href = "/"
      })
      .catch((err) => {
        console.log(err)
        const errorMessage = err.response
          ? err.response.data.context.message
          : err.message
        this.setState({ isLoggingIn: false, error: errorMessage })
      })
  }

  googleFail(response) {
    console.log(response)
    this.setState({ isLoggingIn: false, error: response.details })
  }

  render() {
    return (
      <div>
        <div className={!this.state.error ? "hidden" : ""}>
          <Alert color="danger">{this.state.error}</Alert>
        </div>
        <Jumbotron>
          <h1 className="display-3">{process.env.REACT_APP_PROJECT_NAME}</h1>
          <p className="lead">
            Welcome to the {process.env.REACT_APP_PROJECT_NAME}
          </p>
          <hr className="my-2" />
          <p>
            You must have an account to use {process.env.REACT_APP_PROJECT_NAME}
            .
          </p>
          <p className="lead">
            <GoogleLogin
              clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
              buttonText="Driver Login"
              onSuccess={this.googleDriverSuccess}
              onFailure={this.googleFail}
            />{" "}
            <GoogleLogin
              clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
              buttonText="Admin Login"
              onSuccess={this.googleAdminSuccess}
              onFailure={this.googleFail}
            />
            <div className={!this.state.isLoggingIn ? "hidden" : ""}>
              <Spinner color="primary" />
            </div>
          </p>
        </Jumbotron>
      </div>
    )
  }
}
