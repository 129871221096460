import React from 'react';
import { Row, Col, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Input, InputGroup, InputGroupAddon } from 'reactstrap';

import FormButtons from './FormButtons';

export default class MenuItemOptionForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isSaving: false
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
  }

  handleSubmit(event) {
    this.setState({ isSaving: true })

    event.preventDefault();

    const formData = new FormData(event.target);
    var menuItemOptionObject = {
      id: formData.get('id'),
      menuItemOptionGroupId: this.props.menuItemOptionGroup.id,
      name: formData.get('name')
    };

    const additionalPrice = formData.get('additionalPrice');
    menuItemOptionObject['additionalPrice'] = additionalPrice ? Math.round(parseFloat(additionalPrice) * 100) : null;

    this.props.onSave(menuItemOptionObject).then(() => {
      this.setState({ isSaving: false })
    }).catch(err => {
      alert(err.response ? err.response.data.context.message : err)
      this.setState({ isSaving: false })
    })
  }

  render() {
    return (
      <Modal isOpen={this.props.isOpen}>
        <Form onSubmit={this.handleSubmit}>
          <Input type="hidden" name="id" value={this.props.menuItemOption ? this.props.menuItemOption.id : ''} />
          <ModalHeader>{this.props.menuItemOption ? 'Edit Option' : 'New Option for ' + (this.props.menuItem ? this.props.menuItem.name : '')}</ModalHeader>
          <ModalBody>
            <Row>
              <Col xs="8">
                <FormGroup>
                  <Label>Name<sup>*</sup></Label>
                  <Input type="text" name="name" defaultValue={this.props.menuItemOption ? this.props.menuItemOption.name : ''} />
                </FormGroup>
              </Col>
              <Col xs="4">
                <FormGroup>
                  <Label>Additional Price</Label>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">$</InputGroupAddon>
                    <Input type="number" step="0.01" placeholder="0.00" name="additionalPrice" defaultValue={this.props.menuItemOption && typeof (this.props.menuItemOption.additionalPrice) === 'number' ? this.props.menuItemOption.additionalPrice / 100 : ''} />
                  </InputGroup>
                </FormGroup>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <FormButtons isSaving={this.state.isSaving} onCancel={this.props.onCancel}>{this.props.menuItemOption ? 'Save' : 'Create Option'}</FormButtons>
          </ModalFooter>
        </Form>
      </Modal>
    )
  }
}
