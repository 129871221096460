import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input
} from "reactstrap"
import FormButtons from "./FormButtons"
import React from "react"

export default class MenuForm extends React.Component {
  constructor(props) {
    super(props)

    this.handleSubmit = this.handleSubmit.bind(this)
  }

  componentDidMount() {}

  handleSubmit(event) {
    event.preventDefault()
    const formData = new FormData(event.target)

    var menuObject = {
      id: formData.get("id"),
      merchantId: this.props.merchant.id,
      name: formData.get("name")
    }

    this.props.onSave(menuObject)
  }

  render() {
    return (
      <Modal isOpen={this.props.isOpen}>
        <Form onSubmit={this.handleSubmit}>
          <Input
            type="hidden"
            name="id"
            value={this.props.menu ? this.props.menu.id : ""}
          />
          <ModalHeader>
            {this.props.menu
              ? "Edit Menu"
              : "New Menu for " +
                (this.props.merchant ? this.props.merchant.name : "")}
          </ModalHeader>
          <ModalBody>
            <FormGroup>
              <Label>
                Name<sup>*</sup>
              </Label>
              <Input
                type="text"
                name="name"
                defaultValue={this.props.menu ? this.props.menu.name : ""}
              />
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <FormButtons isSaving={false} onCancel={this.props.onCancel}>
              {this.props.menu ? "Save" : "Create Menu"}
            </FormButtons>
          </ModalFooter>
        </Form>
      </Modal>
    )
  }
}
