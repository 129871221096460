import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Table } from 'reactstrap';

import Request from '../lib/Request';
import User from './User';

export default class ActiveFetches extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      drivers: [],
      user: null,
      reload: true,
      lastUpdatedAt: null
    };

    this.reloadData = this.reloadData.bind(this);

    this.showUser = this.showUser.bind(this);
    this.closeUser = this.closeUser.bind(this);
  }

  componentDidMount() {
    this.reloadData()
    setInterval(this.reloadData, 5000)
  }

  reloadData() {
    if (!this.state.reload) { return }

    Request.get('drivers?status=available&include=user&region_id=' + this.props.regionId).then(res => {
      const drivers = res.data.result.drivers;
      this.setState({ drivers: drivers, lastUpdatedAt: new Date() });
    }).catch(err => {
      console.log(err);
      this.setState({ reload: false });
    })
  }

  showUser(user) {
    this.setState({ user: user });
  }
  closeUser() {
    this.setState({ user: null });
  }

  render() {
    return (
      <div>
        <Container fluid>
          <Row>
            <Col xs="8"><h1>Available Drivers</h1></Col>
            <Col xs="4" className="d-flex align-items-center">Last Updated: {this.state.lastUpdatedAt ? this.state.lastUpdatedAt.toLocaleString() : 'never'}</Col>
          </Row>
        </Container>
        <User isOpen={!!this.state.user} user={this.state.user} close={this.closeUser} />
        <Table striped bordered hover>
          <thead>
            <tr><th>Id</th><th>Name</th><th>Email</th><th>Phone</th><th>Location</th></tr>
          </thead>
          <tbody>
            { this.state.drivers.map(driver => <tr>
              <td><Link to="#" onClick={() => this.showUser(driver.user)}>{driver.id}</Link></td>
              <td>{driver.user.firstName + ' ' + driver.user.lastName}</td>
              <td>{driver.user.email}</td>
              <td>{driver.user.phoneNumber}</td>
              <td><a href={'https://www.google.com/maps/search/?api=1&query=' + driver.location.coordinates[0] + ',' + driver.location.coordinates[1]} target="_blank" rel="noopener noreferrer">{driver.location.coordinates[0] + ',' + driver.location.coordinates[1]}</a></td>
              </tr>)}
          </tbody>
        </Table>
      </div>
    )
  }
}
