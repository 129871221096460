import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Input
} from "reactstrap"
import { Button } from "@fetch/ui"
import React from "react"
import Request from "../lib/Request"

export default class UserPermissions extends React.Component {
  constructor(props) {
    super(props)

    this.permissionToggled = this.permissionToggled.bind(this)
    this.addPermission = this.addPermission.bind(this)
    this.removePermission = this.removePermission.bind(this)
    this.permissionTypes = [
      "manageAdmins",
      "manageUserPermissions",
      "manageUserNotifications",
      "createDriver",
      "manageDrivers",
      "manageUsers",
      "manageFetches",
      "managePurchases",
      "manageRegions",
      "manageMerchants",
      "manageCoupons",
      "loginAsUser",
      "bypassMaintenanceMode",
      "runAudits",
      "createFetchInPendingRegion"
    ]
  }

  componentDidMount() {}

  permissionToggled(event) {
    if (event.target.checked) {
      this.addPermission(event.target.name)
    } else {
      this.removePermission(event.target.name)
    }
  }

  addPermission(permissionType) {
    Request.post("user_permissions", {
      userId: this.props.user ? this.props.user.id : null,
      type: permissionType
    })
      .then((res) => {
        console.log(res)
        this.props.onChange()
      })
      .catch((err) => {
        alert(err.response ? err.response.data.context.message : err)
      })
  }

  removePermission(permissionType) {
    Request.post("user_permissions/remove", {
      userId: this.props.user ? this.props.user.id : null,
      type: permissionType
    })
      .then((res) => {
        console.log(res)
        this.props.onChange()
      })
      .catch((err) => {
        alert(err.response ? err.response.data.context.message : err)
      })
  }

  render() {
    return (
      <Modal toggle={this.props.onClose} isOpen={this.props.isOpen}>
        <Input
          type="hidden"
          name="id"
          value={this.props.user ? this.props.user.id : ""}
        />
        <ModalHeader toggle={this.props.onClose}>
          {this.props.user
            ? this.props.user.firstName + " " + this.props.user.lastName
            : ""}{" "}
          Permissions
        </ModalHeader>
        <ModalBody>
          {this.permissionTypes.map((permissionType) => (
            <FormGroup check>
              <Label check>
                <input
                  type="checkbox"
                  name={permissionType}
                  defaultChecked={
                    this.props.user
                      ? this.props.user.permissions
                          .map((userPermission) => userPermission.type)
                          .includes(permissionType)
                      : false
                  }
                  onClick={this.permissionToggled}
                />{" "}
                {permissionType}
              </Label>
            </FormGroup>
          ))}
        </ModalBody>
        <ModalFooter>
          <Button label="Close" onClick={this.props.onClose} outlined small />
        </ModalFooter>
      </Modal>
    )
  }
}
