import Masonry, { ResponsiveMasonry } from "react-responsive-masonry"
import { Spinner, FormGroup, Label, Input, Row, Col } from "reactstrap"
import { Button } from "@fetch/ui"
import React from "react"
import Request from "../lib/Request"
import debounce from "lodash.debounce"

export default class MenuItemFormImgSearch extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      imgs: [],
      isFetching: false,
      fetchError: false,
      query: "",
      modalImg: undefined,
      showImgModal: false
    }
    this.fetchImages = debounce(this.fetchImages.bind(this), 500)
  }

  fetchImages(query) {
    this.setState({ isFetching: true }, () => {
      Request.get(`webimages/search?q=${query}`)
        .then((res) => {
          let imgs = res.data.result.images
            .filter((img, i) => i < 20)
            .map((img, i) => ({ ...img, imageId: i }))
          this.setState({ isFetching: false, fetchError: false, imgs })
        })
        .catch((err) => {
          alert(err.response ? err.response.data.context.message : err)
          this.setState({ isFetching: false, fetchError: true })
        })
    })
  }

  onSearchChange(query) {
    this.setState({ query, showImgModal: false, modalImg: undefined })
    this.fetchImages(query)
  }

  selectImage() {
    this.props.onImgSelected(this.state.modalImg)
    this.setState({ showImgModal: false, modalImg: undefined })
  }

  deselectImage() {
    this.props.onImgDeselected()
    this.setState({ showImgModal: false, modalImg: undefined })
  }

  render() {
    const columnsCountBreakPoints = { 350: 2, 750: 3, 900: 4 }

    return (
      <React.Fragment>
        <FormGroup>
          <Label>Search on Bing</Label>
          <Input
            type="text"
            name="bingsearch"
            value={this.state.query}
            onChange={(e) => this.onSearchChange(e.target.value)}
          />
        </FormGroup>
        {this.state.isFetching && (
          <React.Fragment>
            <Spinner type="grow" color="secondary" style={{ marginRight: 1 }} />
            <Spinner type="grow" color="secondary" style={{ marginRight: 1 }} />
            <Spinner type="grow" color="secondary" style={{ marginRight: 1 }} />
          </React.Fragment>
        )}
        {!this.state.isFetching && this.state.fetchError && <p>Error.</p>}
        {!this.state.isFetching &&
          !this.state.fetchError &&
          this.state.query &&
          this.state.imgs.length === 0 && <p>No results.</p>}
        {!this.state.isFetching &&
          !this.state.fetchError &&
          this.state.query &&
          this.state.imgs.length > 0 && (
            <React.Fragment>
              {this.state.showImgModal && (
                <React.Fragment>
                  <p>Preview</p>
                  <Row>
                    <Col xs="12" sm="6">
                      <p>
                        <small>Full image</small>
                      </p>
                      <img
                        alt="preview"
                        src={this.state.modalImg.thumbnailUrl}
                        className="img-thumbnail"
                        style={{ maxWidth: 200, maxHeight: 200 }}
                      />
                    </Col>
                    <Col xs="12" sm="6">
                      <p>
                        <small>Cropped for thumbnails</small>
                      </p>
                      <div
                        className="img-thumbnail ratio-4-3"
                        style={{
                          backgroundImage: `url(${this.state.modalImg.thumbnailUrl})`,
                          maxWidth: 200,
                          maxHeight: 200
                        }}
                      ></div>
                    </Col>
                  </Row>
                  <div
                    style={{
                      columnGap: "10px",
                      display: "grid",
                      gridTemplateColumns: "auto auto",
                      marginTop: 10,
                      marginBottom: 15,
                      textAlign: "right"
                    }}
                  >
                    {!this.props.selectedImg ||
                    this.props.selectedImg.url !== this.state.modalImg.url ? (
                      <Button
                        label="Select"
                        onClick={() => {
                          this.selectImage()
                        }}
                        secondary
                        small
                      />
                    ) : (
                      <Button
                        label="Deselect"
                        onClick={() => {
                          this.deselectImage()
                        }}
                        secondary
                        small
                      />
                    )}
                    <Button
                      label="Hide"
                      onClick={() => this.setState({ showImgModal: false })}
                      outlined
                      small
                    />
                  </div>
                </React.Fragment>
              )}
              <ResponsiveMasonry
                columnsCountBreakPoints={columnsCountBreakPoints}
              >
                <Masonry gutter={"4px"}>
                  {this.state.imgs.map((image) => (
                    <div
                      style={{
                        width: "100%",
                        position: "relative",
                        cursor: "pointer"
                      }}
                      key={image.imageId}
                    >
                      {this.props.selectedImg &&
                        this.props.selectedImg.url === image.url && (
                          <div
                            style={{
                              cursor: "pointer",
                              position: "absolute",
                              width: 25,
                              height: 25,
                              top: 2,
                              right: 2,
                              border: "2px solid #28a745",
                              borderRadius: "50%",
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "15px",
                              backgroundPosition: "center",
                              backgroundColor: "#ffffff",
                              backgroundImage: `url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e")`
                            }}
                          ></div>
                        )}
                      <img
                        alt="tiny preview"
                        src={image.url}
                        style={{ width: "100%" }}
                        onClick={() =>
                          this.setState({ showImgModal: true, modalImg: image })
                        }
                      />
                    </div>
                  ))}
                </Masonry>
              </ResponsiveMasonry>
            </React.Fragment>
          )}
      </React.Fragment>
    )
  }
}
