/* eslint-disable react/jsx-no-bind, max-statements, max-lines-per-function, no-ternary, no-magic-numbers, react/destructuring-assignment, no-alert, complexity, react/jsx-no-literals, react/jsx-max-depth, max-lines */

import {
  Col,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row
} from "reactstrap"
import React, { useState } from "react"
import FormButtons from "./FormButtons"

const RegionForm = (props) => {
  const [isSaving, setIsSaving] = useState(false)

  const handleSubmit = (event) => {
    setIsSaving(true)

    event.preventDefault()

    const formData = new FormData(event.target)
    const regionObject = {
      deliveryFeeMinMilesBeforeExtra: formData.get(
        "deliveryFeeMinMilesBeforeExtra"
      ),
      fetchDispatchInterval: formData.get("fetchDispatchInterval"),
      id: formData.get("id"),
      maxLatitude: formData.get("maxLatitude"),
      maxLongitude: formData.get("maxLongitude"),
      minLatitude: formData.get("minLatitude"),
      minLongitude: formData.get("minLongitude"),
      name: formData.get("name"),
      noAvailableDriversMessage: formData.get("noAvailableDriversMessage"),
      nonPartnerMessage: formData.get("nonPartnerMessage"),
      overloadPercent: formData.get("overloadPercent"),
      purchaseServiceFeePercent: formData.get("purchaseServiceFeePercent"),
      transactionFeeLabel: formData.get("transactionFeeLabel"),
      transactionFeePercent: formData.get("transactionFeePercent")
    }

    const purchaseServiceFeeMin = formData.get("purchaseServiceFeeMin")
    regionObject.purchaseServiceFeeMin = purchaseServiceFeeMin
      ? Math.round(parseFloat(purchaseServiceFeeMin) * 100)
      : null

    const noPurchaseServiceFee = formData.get("noPurchaseServiceFee")
    regionObject.noPurchaseServiceFee = noPurchaseServiceFee
      ? Math.round(parseFloat(noPurchaseServiceFee) * 100)
      : null

    const deliveryFeeBase = formData.get("deliveryFeeBase")
    regionObject.deliveryFeeBase = deliveryFeeBase
      ? Math.round(parseFloat(deliveryFeeBase) * 100)
      : null

    const deliveryFeePerMileOverMin = formData.get("deliveryFeePerMileOverMin")
    regionObject.deliveryFeePerMileOverMin = deliveryFeePerMileOverMin
      ? Math.round(parseFloat(deliveryFeePerMileOverMin) * 100)
      : null

    props
      .onSave(regionObject)
      .then(() => {
        setIsSaving(false)
      })
      .catch((err) => {
        alert(err.response ? err.response.data.context.message : err)
        setIsSaving(false)
      })
  }

  return (
    <Modal isOpen={props.isOpen}>
      <Form onSubmit={handleSubmit}>
        <Input
          name="id"
          type="hidden"
          value={props.region ? props.region.id : ""}
        />
        <ModalHeader>{props.region ? "Edit Region" : "New Region"}</ModalHeader>
        <ModalBody>
          <Row>
            <Col xs="12">
              <FormGroup>
                <Label>
                  Name<sup>*</sup>
                </Label>
                <Input
                  defaultValue={props.region ? props.region.name : ""}
                  name="name"
                  type="text"
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col xs="6">
              <FormGroup>
                <Label>Min Latitude</Label>
                <Input
                  defaultValue={
                    props.region
                      ? props.region.minCoordinates.coordinates[0]
                      : null
                  }
                  name="minLatitude"
                  step="0.000000001"
                  type="number"
                />
              </FormGroup>
            </Col>
            <Col xs="6">
              <FormGroup>
                <Label>Max Latitude</Label>
                <Input
                  defaultValue={
                    props.region
                      ? props.region.maxCoordinates.coordinates[0]
                      : null
                  }
                  name="maxLatitude"
                  step="0.000000001"
                  type="number"
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col xs="6">
              <FormGroup>
                <Label>Min Longitude</Label>
                <Input
                  defaultValue={
                    props.region
                      ? props.region.minCoordinates.coordinates[1]
                      : null
                  }
                  name="minLongitude"
                  step="0.000000001"
                  type="number"
                />
              </FormGroup>
            </Col>
            <Col xs="6">
              <FormGroup>
                <Label>Max Longitude</Label>
                <Input
                  defaultValue={
                    props.region
                      ? props.region.maxCoordinates.coordinates[1]
                      : null
                  }
                  name="maxLongitude"
                  step="0.000000001"
                  type="number"
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col xs="6">
              <FormGroup>
                <Label>Dispatch Interval</Label>
                <Input
                  defaultValue={
                    props.region &&
                    typeof props.region.fetchDispatchInterval === "number"
                      ? props.region.fetchDispatchInterval
                      : null
                  }
                  name="fetchDispatchInterval"
                  type="number"
                />
              </FormGroup>
            </Col>
            <Col xs="6">
              <FormGroup>
                <Label>Overload Percent</Label>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">%</InputGroupAddon>
                  <Input
                    defaultValue={
                      props.region &&
                      typeof props.region.overloadPercent === "number"
                        ? props.region.overloadPercent
                        : null
                    }
                    name="overloadPercent"
                    type="number"
                  />
                </InputGroup>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col xs="12">
              <FormGroup>
                <Label>No Available Drivers Message</Label>
                <Input
                  defaultValue={
                    props.region ? props.region.noAvailableDriversMessage : ""
                  }
                  name="noAvailableDriversMessage"
                  type="text"
                />
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col xs="12">
              <FormGroup>
                <Label>{"Non Partner Message"}</Label>
                <Input
                  defaultValue={
                    props.region ? props.region.nonPartnerMessage : ""
                  }
                  name="nonPartnerMessage"
                  type="text"
                />
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col xs="4">
              <FormGroup>
                <Label>Purchase Service Fee Percent</Label>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">%</InputGroupAddon>
                  <Input
                    defaultValue={
                      props.region &&
                      typeof props.region.purchaseServiceFeePercent === "number"
                        ? props.region.purchaseServiceFeePercent
                        : null
                    }
                    name="purchaseServiceFeePercent"
                    type="number"
                  />
                </InputGroup>
              </FormGroup>
            </Col>
            <Col xs="4">
              <FormGroup>
                <Label>Purchase Service Fee Min</Label>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">$</InputGroupAddon>
                  <Input
                    defaultValue={
                      props.region &&
                      typeof props.region.purchaseServiceFeeMin === "number"
                        ? props.region.purchaseServiceFeeMin / 100
                        : null
                    }
                    name="purchaseServiceFeeMin"
                    placeholder="0.00"
                    step="0.01"
                    type="number"
                  />
                </InputGroup>
              </FormGroup>
            </Col>
            <Col xs="4">
              <FormGroup>
                <Label>No Purchase Service Fee</Label>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">$</InputGroupAddon>
                  <Input
                    defaultValue={
                      props.region &&
                      typeof props.region.noPurchaseServiceFee === "number"
                        ? props.region.noPurchaseServiceFee / 100
                        : null
                    }
                    name="noPurchaseServiceFee"
                    placeholder="0.00"
                    step="0.01"
                    type="number"
                  />
                </InputGroup>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col xs="4">
              <FormGroup>
                <Label>Delivery Fee Base</Label>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">$</InputGroupAddon>
                  <Input
                    defaultValue={
                      props.region &&
                      typeof props.region.deliveryFeeBase === "number"
                        ? props.region.deliveryFeeBase / 100
                        : null
                    }
                    name="deliveryFeeBase"
                    placeholder="0.00"
                    step="0.01"
                    type="number"
                  />
                </InputGroup>
              </FormGroup>
            </Col>
            <Col xs="4">
              <FormGroup>
                <Label>Delivery Fee Min Miles</Label>
                <Input
                  defaultValue={
                    props.region &&
                    typeof props.region.deliveryFeeMinMilesBeforeExtra ===
                      "number"
                      ? props.region.deliveryFeeMinMilesBeforeExtra
                      : null
                  }
                  name="deliveryFeeMinMilesBeforeExtra"
                  step="0.01"
                  type="number"
                />
              </FormGroup>
            </Col>
            <Col xs="4">
              <FormGroup>
                <Label>Deliver Fee Per Mile Over Min</Label>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">$</InputGroupAddon>
                  <Input
                    defaultValue={
                      props.region &&
                      typeof props.region.deliveryFeePerMileOverMin === "number"
                        ? props.region.deliveryFeePerMileOverMin / 100
                        : null
                    }
                    name="deliveryFeePerMileOverMin"
                    placeholder="0.00"
                    step="0.01"
                    type="number"
                  />
                </InputGroup>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col xs="4">
              <FormGroup>
                <Label>Transaction Fee Percent</Label>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">%</InputGroupAddon>
                  <Input
                    defaultValue={
                      props.region &&
                      typeof props.region.transactionFeePercent === "number"
                        ? props.region.transactionFeePercent
                        : null
                    }
                    name="transactionFeePercent"
                    type="number"
                  />
                </InputGroup>
              </FormGroup>
            </Col>
            <Col xs="8">
              <FormGroup>
                <Label>Transaction Fee Label</Label>
                <InputGroup>
                  <Input
                    defaultValue={
                      props.region ? props.region.transactionFeeLabel : ""
                    }
                    name="transactionFeeLabel"
                    type="text"
                  />
                </InputGroup>
              </FormGroup>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <FormButtons isSaving={isSaving} onCancel={props.onCancel}>
            {props.region ? "Save" : "Create Option"}
          </FormButtons>
        </ModalFooter>
      </Form>
    </Modal>
  )
}

export default RegionForm
