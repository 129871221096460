import {
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input
} from "reactstrap"
import FormButtons from "./FormButtons"
import React from "react"

export default class MerchantForm extends React.Component {
  constructor(props) {
    super(props)

    this.handleSubmit = this.handleSubmit.bind(this)
  }

  componentDidMount() {}

  handleSubmit(event) {
    event.preventDefault()
    const formData = new FormData(event.target)

    var merchantObject = {
      id: formData.get("id"),
      name: formData.get("name"),
      userFirstName: formData.get("userFirstName"),
      userLastName: formData.get("userLastName"),
      userEmail: formData.get("userEmail"),
      userPhoneNumber: formData.get("userPhoneNumber")
    }

    this.props.onSave(merchantObject)
  }

  render() {
    return (
      <Modal isOpen={this.props.isOpen}>
        <Form onSubmit={this.handleSubmit}>
          <Input
            type="hidden"
            name="id"
            value={this.props.merchant ? this.props.merchant.id : ""}
          />
          <ModalHeader>
            {this.props.merchant ? "Edit Merchant" : "New Merchant"}
          </ModalHeader>
          <ModalBody>
            <FormGroup>
              <Label>Name</Label>
              <Input
                type="text"
                name="name"
                defaultValue={
                  this.props.merchant ? this.props.merchant.name : ""
                }
              />
            </FormGroup>
            {!this.props.merchant ? (
              <div>
                <Row>
                  <Col xs="4">
                    <FormGroup>
                      <Label>First Name</Label>
                      <Input
                        type="text"
                        name="userFirstName"
                        defaultValue={
                          this.props.merchant
                            ? this.props.merchant.userFirstName
                            : ""
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col xs="4">
                    <FormGroup>
                      <Label>Last Name</Label>
                      <Input
                        type="text"
                        name="userLastName"
                        defaultValue={
                          this.props.merchant
                            ? this.props.merchant.userLastName
                            : ""
                        }
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col xs="4">
                    <FormGroup>
                      <Label>Email</Label>
                      <Input
                        type="text"
                        name="userEmail"
                        defaultValue={
                          this.props.merchant
                            ? this.props.merchant.userEmail
                            : ""
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col xs="4">
                    <FormGroup>
                      <Label>Phone Number</Label>
                      <Input
                        type="text"
                        name="userPhoneNumber"
                        defaultValue={
                          this.props.merchant
                            ? this.props.merchant.userPhoneNumber
                            : ""
                        }
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </div>
            ) : (
              ""
            )}
          </ModalBody>
          <ModalFooter>
            <FormButtons isSaving={false} onCancel={this.props.onCancel}>
              {this.props.merchant ? "Save" : "Create Merchant"}
            </FormButtons>
          </ModalFooter>
        </Form>
      </Modal>
    )
  }
}
