/* eslint-disable react/jsx-no-bind, no-ternary, react/jsx-max-depth */

import {
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from "reactstrap"
import FormButtons from "./FormButtons"
import React from "react"

const AdminForm = ({ admin, cancel, isOpen, save }) => {
  const handleSubmit = (event) => {
    event.preventDefault()
    const formData = new FormData(event.target)

    const adminObject = {
      id: formData.get("id")
    }

    save(adminObject)
  }

  return (
    <Modal isOpen={isOpen}>
      <Form onSubmit={handleSubmit}>
        <Input name="id" type="hidden" value={admin ? admin.id : ""} />
        <ModalHeader>{"New Admin"}</ModalHeader>
        <ModalBody>
          <FormGroup check>
            <Label check>
              <Input type="checkbox" />
              {" Check me out"}
            </Label>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <FormButtons isSaving={false} onCancel={cancel} save={save}>
            {admin ? "Save" : "Create Admin"}
          </FormButtons>
        </ModalFooter>
      </Form>
    </Modal>
  )
}

export default AdminForm
