import React from "react"
import { GoogleLogout } from "react-google-login"

export default class Logout extends React.Component {
  logout() {
    sessionStorage.removeItem("currentUser")
    window.location.href = "/"
  }

  render() {
    return (
      <div>
        <GoogleLogout
          clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
          buttonText="Logout"
          onLogoutSuccess={this.logout}
        ></GoogleLogout>
      </div>
    )
  }
}
