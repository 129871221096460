import {
  Breadcrumb,
  BreadcrumbItem,
  Container,
  Row,
  Col,
  Table
} from "reactstrap"
import { GoChevronUp, GoChevronDown } from "react-icons/go"
import { Button } from "@fetch/ui"
import LoadingIndicator from "./LoadingIndicator"
import MenuItemOptionForm from "./MenuItemOptionForm"
import React from "react"
import Request from "../lib/Request"
import styled from "styled-components"

require("../lib/Extensions")

const Buttons = styled.td`
  column-gap: 10px;
  display: grid;
  grid-template-columns: auto auto;
  row-gap: 10px;
`

export default class MenuItemOptions extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      merchant: null,
      isLoading: false,
      menu: null,
      menuItem: null,
      menuItemOptionGroup: null,
      menuItemOptions: [],
      newMenuItemOption: false,
      editMenuItemOption: false,
      menuItemOption: null
    }

    this.loadData = this.loadData.bind(this)

    this.newMenuItemOption = this.newMenuItemOption.bind(this)
    this.cancelNewMenuItemOption = this.cancelNewMenuItemOption.bind(this)
    this.saveNewMenuItemOption = this.saveNewMenuItemOption.bind(this)

    this.editMenuItemOption = this.editMenuItemOption.bind(this)
    this.cancelEditMenuItemOption = this.cancelEditMenuItemOption.bind(this)
    this.saveEditMenuItemOption = this.saveEditMenuItemOption.bind(this)

    this.deleteMenuItemOption = this.deleteMenuItemOption.bind(this)

    this.moveMenuItemOptionUp = this.moveMenuItemOptionUp.bind(this)
    this.moveMenuItemOptionDown = this.moveMenuItemOptionDown.bind(this)
    this.sortMenuItemOptions = this.sortMenuItemOptions.bind(this)
  }

  componentDidMount() {
    this.loadData()
  }

  loadData() {
    this.setState({ isLoading: true })

    const getMerchant = Request.get(
      "merchants/" + this.props.match.params.merchant_id
    )
    const getMenu = Request.get("menus/" + this.props.match.params.menu_id)
    const getMenuItem = Request.get(
      "menu_items/" + this.props.match.params.menu_item_id
    )
    const getMenuItemOptionGroup = Request.get(
      "menu_item_option_groups/" +
        this.props.match.params.menu_item_option_group_id
    )
    const getMenuItemOptions = Request.get(
      "menu_item_options?menu_item_option_group_id=" +
        this.props.match.params.menu_item_option_group_id
    )
    Promise.all([
      getMerchant,
      getMenu,
      getMenuItem,
      getMenuItemOptionGroup,
      getMenuItemOptions
    ])
      .then((values) => {
        const [
          merchantRes,
          menuRes,
          menuItemRes,
          menuItemOptionGroupRes,
          menuItemOptionsRes
        ] = values

        const merchant = merchantRes.data.result.merchant
        const menu = menuRes.data.result.menu
        const menuItem = menuItemRes.data.result.menuItem
        const menuItemOptionGroup =
          menuItemOptionGroupRes.data.result.menuItemOptionGroup
        const menuItemOptions = menuItemOptionsRes.data.result.menuItemOptions
        this.setState({
          merchant: merchant,
          menu: menu,
          menuItem: menuItem,
          menuItemOptionGroup: menuItemOptionGroup,
          menuItemOptions: menuItemOptions,
          isLoading: false
        })
      })
      .catch((err) => {
        alert(err.response ? err.response.data.context.message : err)
        this.setState({ isLoading: false })
      })
  }

  newMenuItemOption() {
    this.setState({ newMenuItemOption: true })
  }
  cancelNewMenuItemOption() {
    this.setState({ newMenuItemOption: false })
  }
  saveNewMenuItemOption(data) {
    console.log(data)

    return Request.post("menu_item_options", data).then((res) => {
      console.log(data)
      this.setState({ newMenuItemOption: false })
      this.loadData()
    })
  }

  editMenuItemOption(menuItemOption) {
    console.log(menuItemOption)
    this.setState({ editMenuItemOption: true, menuItemOption: menuItemOption })
  }
  cancelEditMenuItemOption() {
    this.setState({ editMenuItemOption: false })
  }
  saveEditMenuItemOption(data) {
    console.log(data)

    return Request.post("menu_item_options/" + data.id, data).then((res) => {
      console.log(res)
      this.setState({ editMenuItemOption: false })
      this.loadData()
    })
  }

  deleteMenuItemOption(menuItemOption) {
    if (
      window.confirm(`Are you sure you want to delete ${menuItemOption.name}?`)
    ) {
      Request.post("menu_item_options/" + menuItemOption.id + "/delete")
        .then((res) => {
          console.log(res)
          this.loadData()
        })
        .catch((err) => {
          alert(err.response ? err.response.data.context.message : err)
        })
    }
  }

  moveMenuItemOptionUp(menuItemOption) {
    var menuItemOptionIds = this.state.menuItemOptions.map(
      (menuItemOption) => menuItemOption.id
    )
    const index = menuItemOptionIds.indexOf(menuItemOption.id)
    if (index <= 0) {
      return
    }

    menuItemOptionIds.splice(
      index - 1,
      0,
      menuItemOptionIds.splice(index, 1)[0]
    )
    this.sortMenuItemOptions(menuItemOptionIds)
  }

  moveMenuItemOptionDown(menuItemOption) {
    var menuItemOptionIds = this.state.menuItemOptions.map(
      (menuItemOption) => menuItemOption.id
    )
    const index = menuItemOptionIds.indexOf(menuItemOption.id)
    if (index >= this.state.menuItemOptions.length - 1) {
      return
    }

    menuItemOptionIds.splice(
      index + 1,
      0,
      menuItemOptionIds.splice(index, 1)[0]
    )

    this.sortMenuItemOptions(menuItemOptionIds)
  }

  sortMenuItemOptions(menuItemOptionIds) {
    Request.post(
      "menu_item_option_groups/" +
        this.props.match.params.menu_item_option_group_id +
        "/sort_options",
      { menuItemOptionIds: menuItemOptionIds }
    )
      .then(() => {
        this.loadData()
      })
      .catch((err) => {
        alert(err.response ? err.response.data.context.message : err)
      })
  }

  render() {
    return (
      <div>
        <Breadcrumb>
          <BreadcrumbItem>
            <a href="/merchants">Merchants</a>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <a
              href={
                this.state.merchant
                  ? "/merchants/" + this.state.merchant.id
                  : ""
              }
            >
              {this.state.merchant && this.state.merchant.name}
            </a>
          </BreadcrumbItem>
          <BreadcrumbItem>Menus</BreadcrumbItem>
          <BreadcrumbItem>
            <a
              href={
                this.state.merchant
                  ? "/merchants/" +
                    this.state.merchant.id +
                    "/menus/" +
                    this.state.menu.id +
                    "/items"
                  : ""
              }
            >
              {this.state.menu ? this.state.menu.name : ""}
            </a>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <a
              href={
                this.state.merchant
                  ? "/merchants/" +
                    this.state.merchant.id +
                    "/menus/" +
                    this.state.menu.id +
                    "/items/" +
                    this.state.menuItem.id +
                    "/option_groups"
                  : ""
              }
            >
              {this.state.menuItem ? this.state.menuItem.name : ""}
            </a>
          </BreadcrumbItem>
          <BreadcrumbItem active>
            {this.state.menuItemOptionGroup &&
              this.state.menuItemOptionGroup.name}
          </BreadcrumbItem>
        </Breadcrumb>
        <Container fluid>
          <Row>
            <Col xs="8">
              <h1>
                {this.state.menuItemOptionGroup &&
                  this.state.menuItemOptionGroup.name}{" "}
                <LoadingIndicator isLoading={this.state.isLoading} />
              </h1>
            </Col>
            <Col xs="4" className="d-flex align-items-center">
              <Button
                label="+New Option"
                onClick={this.newMenuItemOption}
                outlined
                secondary
                small
              />
            </Col>
          </Row>
        </Container>
        <MenuItemOptionForm
          isOpen={this.state.newMenuItemOption}
          onCancel={this.cancelNewMenuItemOption}
          onSave={this.saveNewMenuItemOption}
          menuItemOptionGroup={this.state.menuItemOptionGroup}
        />
        <MenuItemOptionForm
          isOpen={this.state.editMenuItemOption}
          onCancel={this.cancelEditMenuItemOption}
          onSave={this.saveEditMenuItemOption}
          menuItemOption={this.state.menuItemOption}
          menuItemOptionGroup={this.state.menuItemOptionGroup}
        />
        <Table striped bordered responsive>
          <thead>
            <tr>
              <th>Actions</th>
              <th width="80">Id</th>
              <th>Name</th>
              <th>Additional Price</th>
            </tr>
          </thead>
          <tbody>
            {this.state.menuItemOptions.map((menuItemOption) => (
              <tr>
                <Buttons>
                  <Button
                    label="Edit"
                    onClick={() => this.editMenuItemOption(menuItemOption)}
                    outlined
                    secondary
                    small
                  />
                  <Button
                    label="Delete"
                    onClick={() => this.deleteMenuItemOption(menuItemOption)}
                    secondary
                    small
                  />
                  {menuItemOption.index > 0 ? (
                    <Button
                      iconEnd={<GoChevronUp />}
                      onClick={() => this.moveMenuItemOptionUp(menuItemOption)}
                      outlined
                      small
                    />
                  ) : (
                    ""
                  )}
                  {menuItemOption.index <
                  this.state.menuItemOptions.length - 1 ? (
                    <Button
                      iconEnd={<GoChevronDown />}
                      onClick={() =>
                        this.moveMenuItemOptionDown(menuItemOption)
                      }
                      outlined
                      small
                    />
                  ) : (
                    ""
                  )}
                </Buttons>
                <td>{menuItemOption.id}</td>
                <td>{menuItemOption.name}</td>
                <td>
                  {typeof menuItemOption.additionalPrice === "number"
                    ? menuItemOption.additionalPrice.formatDollars()
                    : null}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    )
  }
}
