import React from "react"
import Request from "../lib/Request"
import { Breadcrumb, BreadcrumbItem } from "reactstrap"

import Places from "./Places"
import Menus from "./Menus"
import EmburseMerchantSearch from "./EmburseMerchantSearch"

export default class ViewMerchant extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      merchant: null
    }
  }

  componentDidMount() {
    this.reloadData()
  }

  reloadData() {
    Request.get("merchants/" + this.props.match.params.merchant_id)
      .then((merchantRes) => {
        const merchant = merchantRes.data.result.merchant
        this.setState({ merchant: merchant })
      })
      .catch((err) => {
        console.log(err)
      })
  }

  render() {
    return (
      <div>
        <Breadcrumb>
          <BreadcrumbItem>
            <a href="/merchants">Merchants</a>
          </BreadcrumbItem>
          <BreadcrumbItem>
            {this.state.merchant && this.state.merchant.name}
          </BreadcrumbItem>
        </Breadcrumb>
        <Places merchantId={this.props.match.params.merchant_id} />
        <hr />
        <Menus merchantId={this.props.match.params.merchant_id} />
        <hr />
        <EmburseMerchantSearch />
      </div>
    )
  }
}
