import React from 'react';
import { Row, Col, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Input } from 'reactstrap';

import FormButtons from './FormButtons';

export default class MenuItemOptionGroupForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isSaving: false
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
  }

  handleSubmit(event) {
    this.setState({ isSaving: true })

    event.preventDefault();

    const formData = new FormData(event.target);
    var menuItemOptionGroupObject = {
      id: formData.get('id'),
      menuItemId: this.props.menuItem.id,
      name: formData.get('name'),
      minSelections: formData.get('minSelections'),
      maxSelections: formData.get('maxSelections')
    };

    this.props.onSave(menuItemOptionGroupObject).then(() => {
      this.setState({ isSaving: false })
    }).catch(err => {
      alert(err.response ? err.response.data.context.message : err)
      this.setState({ isSaving: false })
    })
  }

  render() {
    return (
      <Modal isOpen={this.props.isOpen}>
        <Form onSubmit={this.handleSubmit}>
          <Input type="hidden" name="id" value={this.props.menuItemOptionGroup ? this.props.menuItemOptionGroup.id : ''} />
          <ModalHeader>{this.props.menuItemOptionGroup ? 'Edit Option Group' : 'New Option Group for ' + (this.props.menuItem ? this.props.menuItem.name : '')}</ModalHeader>
          <ModalBody>
            <Row>
              <Col xs="4">
                <FormGroup>
                  <Label>Name<sup>*</sup></Label>
                  <Input type="text" name="name" defaultValue={this.props.menuItemOptionGroup ? this.props.menuItemOptionGroup.name : ''} />
                </FormGroup>
              </Col>
              <Col xs="4">
                <FormGroup>
                  <Label>Min Selections</Label>
                  <Input type="text" name="minSelections" defaultValue={this.props.menuItemOptionGroup ? this.props.menuItemOptionGroup.minSelections : ''} />
                </FormGroup>
              </Col>
              <Col xs="4">
                <FormGroup>
                  <Label>Max Selections</Label>
                  <Input type="text" name="maxSelections" defaultValue={this.props.menuItemOptionGroup ? this.props.menuItemOptionGroup.maxSelections : ''} />
                </FormGroup>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <FormButtons isSaving={this.state.isSaving} onCancel={this.props.onCancel}>{this.props.menuItemOptionGroup ? 'Save' : 'Create Option Group'}</FormButtons>
          </ModalFooter>
        </Form>
      </Modal>
    )
  }
}
