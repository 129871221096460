import colors from './colors.js';

const theme = {
  font: {
    family: '"Helvetica Neue", Helvetica, Roboto, Arial, sans-serif'
  },
  palette: {
    disabled: {
      contrast: colors.white,
      main: colors.dustyGray
    },
    error: {
      contrast: colors.white,
      main: colors.sunsetOrange
    },
    primary: {
      contrast: colors.white,
      main: colors.thunder
    },
    secondary: {
      contrast: colors.white,
      main: colors.seance
    },
    subtitle: {
      contrast: colors.white,
      main: colors.dustyGray
    },
    text: {
      contrast: colors.white,
      main: colors.thunder
    }
  }
};

export { theme as default };
