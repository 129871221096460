import { Jumbotron, Container, Alert } from "reactstrap"
import { Button } from "@fetch/ui"
import Constants from "../lib/Constants"
import LoadingIndicator from "./LoadingIndicator"
import React from "react"
import Request from "../lib/Request"

export default class AdminDashboard extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      driver: null
    }

    this.loadDriver = this.loadDriver.bind(this)
    this.connectStripe = this.connectStripe.bind(this)
    this.viewStripeAccount = this.viewStripeAccount.bind(this)
  }

  componentDidMount() {
    this.loadDriver()
  }

  loadDriver() {
    this.setState({ isLoading: true })

    Request.get("users/" + this.props.currentUser.user.id + "?include=driver")
      .then((userRes) => {
        const driver = userRes.data.result.user.driver
        this.setState({ driver: driver })
      })
      .catch((err) => {
        alert(err.response ? err.response.data.context.message : err)
      })
  }

  connectStripe() {
    var url =
      "https://connect.stripe.com/express/oauth/authorize?redirect_uri=" +
      Constants.BaseUrl +
      "/stripe_connect&client_id=" +
      process.env.REACT_APP_STRIPE_CLIENT_ID +
      "&suggested_capabilities[]=transfers"
    url +=
      "&stripe_user[country]=US&stripe_user[business_type]=individual&stripe_user[url]=" +
      Constants.BaseUrl +
      "/drivers/" +
      this.state.driver.id
    if (this.props.currentUser.user.email) {
      url += "&stripe_user[email]=" + this.props.currentUser.user.email
    }
    if (this.props.currentUser.user.firstName) {
      url += "&stripe_user[first_name]=" + this.props.currentUser.user.firstName
    }
    if (this.props.currentUser.user.lastName) {
      url += "&stripe_user[last_name]=" + this.props.currentUser.user.lastName
    }

    window.location.href = url
  }

  viewStripeAccount() {
    Request.get("drivers/stripe_login")
      .then((stripeLoginRes) => {
        const url = stripeLoginRes.data.result.url
        window.open(url)
      })
      .catch((err) => {
        alert(err.response ? err.response.data.context.message : err)
      })
  }

  render() {
    return (
      <div>
        <Container fluid>
          <Jumbotron>
            <h1 className="display-3">
              Welcome, {this.props.currentUser.user.firstName}!
            </h1>
            <p className="lead">
              This is the Fetch Portal where you can view your payments and
              update your bank account.
            </p>
            <hr className="my-2" />
            <p>
              {this.state.driver ? (
                this.state.driver.stripeAccountId ? (
                  <Button
                    label="View Stripe Account"
                    onClick={this.viewStripeAccount}
                    secondary
                    small
                  />
                ) : (
                  <div>
                    <Alert color="warning">
                      You must connect to Stripe before you can start driving
                      for Fetch. We use Stripe to process payments so you can
                      get paid.
                    </Alert>
                    <Button
                      label="Connect to Stripe"
                      onClick={this.connectStripe}
                      secondary
                      small
                    />
                  </div>
                )
              ) : (
                <LoadingIndicator isLoading />
              )}
            </p>
          </Jumbotron>
        </Container>
      </div>
    )
  }
}
