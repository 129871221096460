import {
  Col,
  Breadcrumb,
  BreadcrumbItem,
  Container,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  FormGroup,
  Label,
  Input
} from "reactstrap"
import { GoChevronUp, GoChevronDown } from "react-icons/go"
import { Button } from "@fetch/ui"
import FormButtons from "./FormButtons"
import LoadingIndicator from "./LoadingIndicator"
import MenuItemForm from "./MenuItemForm"
import React from "react"
import Request from "../lib/Request"
import styled from "styled-components"

require("../lib/Extensions")

const Buttons = styled.td`
  column-gap: 10px;
  display: grid;
  grid-template-columns: auto auto auto;
  row-gap: 10px;
`

export default class MenuItems extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      merchant: null,
      isLoading: false,
      menu: null,
      menuItems: [],
      newMenuItem: false,
      editMenuItem: false,
      menuItem: null,
      showDuplicateModal: false,
      itemToDuplicate: null,
      menuIdDuplicate: ""
    }

    this.loadData = this.loadData.bind(this)

    this.newMenuItem = this.newMenuItem.bind(this)
    this.cancelNewMenuItem = this.cancelNewMenuItem.bind(this)
    this.saveNewMenuItem = this.saveNewMenuItem.bind(this)

    this.editMenuItem = this.editMenuItem.bind(this)
    this.cancelEditMenuItem = this.cancelEditMenuItem.bind(this)
    this.saveEditMenuItem = this.saveEditMenuItem.bind(this)

    this.deleteMenuItem = this.deleteMenuItem.bind(this)

    this.moveMenuItemUp = this.moveMenuItemUp.bind(this)
    this.moveMenuItemDown = this.moveMenuItemDown.bind(this)

    this.closeDuplicateModal = this.closeDuplicateModal.bind(this)
    this.openDuplicateModal = this.openDuplicateModal.bind(this)
    this.duplicateMenuItem = this.duplicateMenuItem.bind(this)
  }

  componentDidMount() {
    this.loadData()
  }

  loadData() {
    this.setState({ isLoading: true })

    const getMerchant = Request.get(
      "merchants/" + this.props.match.params.merchant_id
    )
    const getMenu = Request.get("menus/" + this.props.match.params.menu_id)
    const getMenuItems = Request.get(
      "menu_items?menu_id=" + this.props.match.params.menu_id + "&include=image"
    )
    Promise.all([getMerchant, getMenu, getMenuItems])
      .then((values) => {
        const [merchantRes, menuRes, menuItemsRes] = values

        const merchant = merchantRes.data.result.merchant
        const menu = menuRes.data.result.menu
        const menuItems = menuItemsRes.data.result.menuItems
        this.setState({
          merchant: merchant,
          menu: menu,
          menuItems: menuItems,
          isLoading: false
        })
      })
      .catch((err) => {
        alert(err.response ? err.response.data.context.message : err)
        this.setState({ isLoading: false })
      })
  }

  newMenuItem() {
    this.setState({ newMenuItem: true })
  }
  cancelNewMenuItem() {
    this.setState({ newMenuItem: false })
  }
  saveNewMenuItem(data) {
    console.log(data)

    return Request.post("menu_items", data).then((res) => {
      console.log(data)
      this.setState({ newMenuItem: false })
      this.loadData()
    })
  }

  editMenuItem(menuItem) {
    console.log(menuItem)
    this.setState({ editMenuItem: true, menuItem: menuItem })
  }
  cancelEditMenuItem() {
    this.setState({ editMenuItem: false })
  }
  duplicateMenuItem() {
    if (this.state.menuIdDuplicate) {
      Request.post(
        "menu_items/" + this.state.itemToDuplicate.id + "/duplicate",
        { menuId: this.state.menuIdDuplicate }
      )
        .then((res) => {
          console.log(res)
          this.loadData()
          this.setState({ showDuplicateModal: false, itemToDuplicate: null })
        })
        .catch((err) => {
          alert(err.response ? err.response.data.context.message : err)
        })
    } else {
      Request.post("menu_items/" + this.state.itemToDuplicate.id + "/duplicate")
        .then((res) => {
          console.log(res)
          this.loadData()
          this.setState({ showDuplicateModal: false, itemToDuplicate: null })
        })
        .catch((err) => {
          alert(err.response ? err.response.data.context.message : err)
        })
    }
  }
  saveEditMenuItem(data) {
    console.log(data)

    return Request.post("menu_items/" + data.id, data).then((res) => {
      console.log(res)
      this.setState({ editMenuItem: false })
      this.loadData()
    })
  }

  deleteMenuItem(menuItem) {
    if (window.confirm(`Are you sure you want to delete ${menuItem.name}?`)) {
      Request.post("menu_items/" + menuItem.id + "/delete")
        .then((res) => {
          console.log(res)
          this.loadData()
        })
        .catch((err) => {
          alert(err.response ? err.response.data.context.message : err)
        })
    }
  }

  moveMenuItemUp(menuItem) {
    var menuItemIds = this.state.menuItems.map((menuItem) => menuItem.id)
    const index = menuItemIds.indexOf(menuItem.id)
    if (index <= 0) {
      return
    }

    menuItemIds.splice(index - 1, 0, menuItemIds.splice(index, 1)[0])

    Request.post("menus/" + this.props.match.params.menu_id + "/sort_items", {
      menuItemIds: menuItemIds
    })
      .then(() => {
        this.loadData()
      })
      .catch((err) => {
        alert(err.response ? err.response.data.context.message : err)
      })
  }

  moveMenuItemDown(menuItem) {
    var menuItemIds = this.state.menuItems.map((menuItem) => menuItem.id)
    const index = menuItemIds.indexOf(menuItem.id)
    if (index >= this.state.menuItems.length - 1) {
      return
    }

    menuItemIds.splice(index + 1, 0, menuItemIds.splice(index, 1)[0])

    Request.post("menus/" + this.props.match.params.menu_id + "/sort_items", {
      menuItemIds: menuItemIds
    })
      .then(() => {
        this.loadData()
      })
      .catch((err) => {
        alert(err.response ? err.response.data.context.message : err)
      })
  }

  navigateToMenuItemOptionGroupsForMenuItem(menuItemId) {
    window.location.href = `/merchants/${this.state.merchant.id}/menus/${this.state.menu.id}/items/${menuItemId}/option_groups`
  }

  openDuplicateModal(menuItem) {
    this.setState({ showDuplicateModal: true, itemToDuplicate: menuItem })
  }

  closeDuplicateModal() {
    this.setState({
      showDuplicateModal: false,
      itemToDuplicate: null,
      menuIdDuplicate: ""
    })
  }

  render() {
    return (
      <div>
        <Breadcrumb>
          <BreadcrumbItem>
            <a href="/merchants">Merchants</a>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <a
              href={
                this.state.merchant
                  ? "/merchants/" + this.state.merchant.id
                  : ""
              }
            >
              {this.state.merchant && this.state.merchant.name}
            </a>
          </BreadcrumbItem>
          <BreadcrumbItem>Menus</BreadcrumbItem>
          <BreadcrumbItem active>
            {this.state.menu && this.state.menu.name}
          </BreadcrumbItem>
        </Breadcrumb>
        <Container fluid>
          <Row>
            <Col xs="8">
              <h1>
                {this.state.menu && this.state.menu.name}{" "}
                <LoadingIndicator isLoading={this.state.isLoading} />
              </h1>
              <h3>{this.state.menu && `Id: ${this.state.menu.id}`}</h3>
            </Col>
            <Col xs="4" className="d-flex align-items-center">
              <Button
                label="+ New Item"
                onClick={this.newMenuItem}
                outlined
                secondary
                small
              />
            </Col>
          </Row>
        </Container>
        <MenuItemForm
          isOpen={this.state.newMenuItem}
          onCancel={this.cancelNewMenuItem}
          onSave={this.saveNewMenuItem}
          menu={this.state.menu}
        />
        <MenuItemForm
          isOpen={this.state.editMenuItem}
          onCancel={this.cancelEditMenuItem}
          onSave={this.saveEditMenuItem}
          menuItem={this.state.menuItem}
          menu={this.state.menu}
        />
        <Table striped bordered responsive>
          <thead>
            <tr>
              <th>Actions</th>
              <th width="80">Id</th>
              <th>Image</th>
              <th>Name</th>
              <th>Price</th>
              <th>Detail</th>
            </tr>
          </thead>
          <tbody>
            {this.state.menuItems.map((menuItem) => (
              <tr>
                <Buttons>
                  <Button
                    label="Edit"
                    onClick={() => this.editMenuItem(menuItem)}
                    outlined
                    secondary
                    small
                  />
                  <Button
                    label="Duplicate"
                    onClick={() => this.openDuplicateModal(menuItem)}
                    outlined
                    small
                  />
                  <Button
                    label="Delete"
                    onClick={() => this.deleteMenuItem(menuItem)}
                    secondary
                    small
                  />
                  {menuItem.index > 0 ? (
                    <Button
                      iconEnd={<GoChevronUp />}
                      onClick={() => this.moveMenuItemUp(menuItem)}
                      outlined
                      small
                    />
                  ) : (
                    ""
                  )}
                  {menuItem.index < this.state.menuItems.length - 1 ? (
                    <Button
                      iconEnd={<GoChevronDown />}
                      onClick={() => this.moveMenuItemDown(menuItem)}
                      outlined
                      small
                    />
                  ) : (
                    ""
                  )}
                  <Button
                    label="Option Groups"
                    onClick={() =>
                      this.navigateToMenuItemOptionGroupsForMenuItem(
                        menuItem.id
                      )
                    }
                    outlined
                    small
                  />
                </Buttons>
                <td>{menuItem.id}</td>
                <td>
                  {menuItem.image ? (
                    <a
                      href={menuItem.image.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={menuItem.image.url}
                        alt={menuItem.name}
                        width="50"
                        height="50"
                      />
                    </a>
                  ) : (
                    ""
                  )}
                </td>
                <td>{menuItem.name}</td>
                <td>
                  {typeof menuItem.price === "number"
                    ? menuItem.price.formatDollars()
                    : null}
                </td>
                <td>{menuItem.detail}</td>
              </tr>
            ))}
          </tbody>
        </Table>
        {this.state.showDuplicateModal && (
          <Modal
            isOpen={this.state.showDuplicateModal}
            toggle={this.closeDuplicateModal}
          >
            <ModalHeader toggle={this.closeDuplicateModal}>
              Duplicate {this.state.itemToDuplicate.name}
            </ModalHeader>
            <ModalBody>
              <FormGroup>
                <Label for="menuId">Menu Id</Label>
                <Input
                  type="text"
                  name="menuid"
                  id="menuId"
                  placeholder="Menu Id"
                  vallue={this.state.menuIdDuplicate}
                  onChange={(e) =>
                    this.setState({ menuIdDuplicate: e.target.value })
                  }
                />
              </FormGroup>
            </ModalBody>
            <ModalFooter>
              <FormButtons
                isSaving={false}
                onCancel={this.closeDuplicateModal}
                onSave={this.duplicateMenuItem}
                saveButtonType="button"
              >
                {"Duplicate"}
              </FormButtons>
            </ModalFooter>
          </Modal>
        )}
      </div>
    )
  }
}
