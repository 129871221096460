import React from 'react';
import { Spinner } from 'reactstrap';

export default class LoadingIndicator extends React.Component {
  render() {
    if (this.props.isLoading) {
      return (
        <Spinner color="primary" />
      )
    } else {
      return null
    }
  }
}
