// @flow
/* eslint-disable no-ternary */

import styled, { type StyledComponent } from "styled-components"
import { Button } from "@fetch/ui"
import React from "react"
// $FlowFixMe Add Module
import { Spinner } from "reactstrap"

const Wrapper: StyledComponent<any, mixed, HTMLElement> = styled.div`
  column-gap: 10px;
  display: grid;
  grid-template-columns: auto auto;
`

type Props = {|
  +children: string,
  +isSaving: boolean,
  +onCancel: () => void,
  +onSave?: () => void,
  +saveButtonType?: string
|}

const FormButtons = ({
  children,
  isSaving,
  onCancel,
  onSave,
  saveButtonType
}: Props) => {
  if (isSaving) {
    return <Spinner color="primary" />
  }

  return (
    <Wrapper>
      <Button label="Cancel" onClick={onCancel} outlined small />

      <Button
        label={children}
        onClick={onSave}
        secondary
        small
        type={saveButtonType ? saveButtonType : "submit"}
      />
    </Wrapper>
  )
}

export default FormButtons
