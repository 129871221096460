/* eslint-disable react/require-optimization, class-methods-use-this, no-empty-function, max-statements, max-lines-per-function, vars-on-top, no-var,react/no-set-state, dot-notation, no-ternary, no-magic-numbers, react/destructuring-assignment, no-alert, complexity, react/jsx-no-literals, react/jsx-max-depth, max-lines, func-style, id-length, no-plusplus, spaced-comment, prefer-const, prefer-destructuring, one-var, no-unneeded-ternary, no-undef-init, no-undefined, no-negated-condition, react/no-access-state-in-setstate, max-depth, max-params, sort-keys, prefer-template, react/jsx-sort-props, react/jsx-no-bind, react/no-array-index-key */

import {
  Alert,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table
} from "reactstrap"
import FormButtons from "./FormButtons"
import React from "react"
import Request from "../lib/Request"
import cloneDeep from "lodash.clonedeep"
import moment from "moment"

const DEFAULT_HOURS = {
  sunday: [
    ["", ""],
    ["", ""],
    ["", ""]
  ],
  saturday: [
    ["", ""],
    ["", ""],
    ["", ""]
  ],
  tuesday: [
    ["", ""],
    ["", ""],
    ["", ""]
  ],
  friday: [
    ["", ""],
    ["", ""],
    ["", ""]
  ],
  thursday: [
    ["", ""],
    ["", ""],
    ["", ""]
  ],
  wednesday: [
    ["", ""],
    ["", ""],
    ["", ""]
  ],
  monday: [
    ["", ""],
    ["", ""],
    ["", ""]
  ]
}

const DEFAULT_CLOSED = {
  sunday: true,
  saturday: true,
  tuesday: true,
  friday: true,
  thursday: true,
  wednesday: true,
  monday: true
}

const HOURS_DAYS = [
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
  "sunday"
]

const TIME_SLOT_SIZE = 3

function fillEmptyTimeSlots(timeSlot) {
  if (!timeSlot || timeSlot.length === 0) {
    return [
      ["", ""],
      ["", ""],
      ["", ""]
    ]
  }
  if (timeSlot.length === TIME_SLOT_SIZE) {
    return timeSlot
  }
  for (var i = TIME_SLOT_SIZE - timeSlot.length; i > 0; i--) {
    timeSlot.push(["", ""])
  }
  return timeSlot
}

function removeEmptyTimeSlots(timeSlot) {
  let res = []
  for (var i = 0; i < TIME_SLOT_SIZE; i++) {
    if (timeSlot[i][0] && timeSlot[i][1]) res.push(timeSlot[i])
  }
  return res
}

function fillEmptyDaysAndSlots(hoursKeys, hours, hoursClosed) {
  let missing = []
  //determine missing days in place hours
  HOURS_DAYS.map((day) => {
    if (!(day in hours)) {
      missing.push(day)
    }
    return day
  })
  //fill time slots for ech day
  hoursKeys.map((hk) => {
    hours[hk] = fillEmptyTimeSlots(hours[hk])
    hoursClosed[hk] = false
    return hk
  })
  //add empty time slots for missing days
  missing.map((miss) => {
    hours[miss] = [
      ["", ""],
      ["", ""],
      ["", ""]
    ]
    return miss
  })
}

function padSingleDigitHours(hours) {
  HOURS_DAYS.map((day) => {
    const dayHours = hours[day]
    for (let i = 0; i < dayHours.length; i++) {
      const timeSlot = dayHours[i]
      for (let j = 0; j < timeSlot.length; j++) {
        const time = timeSlot[j]
        if (time !== "") {
          let splitTime = time.split(":")
          let h = splitTime[0],
            m = splitTime[1]
          const missingTrailingZero = h.length < 2
          if (missingTrailingZero) {
            hours[day][i][j] = `0${h}:${m}`
          }
        }
      }
    }
    return day
  })
}

export default class PlaceForm extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isSaving: false,
      hoursValid: true,
      useHours: props.place && props.place.hours ? true : false,
      hoursClosed: cloneDeep(DEFAULT_CLOSED)
    }

    let hours = undefined
    let hoursClosed = cloneDeep(DEFAULT_CLOSED)

    if (props.place) {
      if (!props.place.hours) {
        //no ours override
        hours = cloneDeep(DEFAULT_HOURS)
      } else {
        //generate js object from JSON
        hours = cloneDeep(JSON.parse(props.place.hours))
        let hoursKeys = Object.keys(hours)
        fillEmptyDaysAndSlots(hoursKeys, hours, hoursClosed)
        padSingleDigitHours(hours)
      }
    }

    this.state.hours = hours
    this.state.hoursClosed = hoursClosed

    this.handleSubmit = this.handleSubmit.bind(this)
  }

  componentDidUpdate(prevProps) {
    let updHours = cloneDeep(this.state.hours),
      updHoursClosed = cloneDeep(this.state.hoursClosed)
    const placeRemovedFromProps = prevProps.place && !this.props.place
    const placeAddedToProps = !prevProps.place && this.props.place
    const propsChangedWithDifferentPlace =
      prevProps.place &&
      this.props.place &&
      prevProps.place.id !== this.props.place.id
    const propsChangedSamePlaceDifferentHours =
      prevProps.place &&
      this.props.place &&
      prevProps.place.id === this.props.place.id &&
      prevProps.place.hours !== this.props.place.hours
    const statePlaceUpdNeeded =
      placeAddedToProps ||
      propsChangedWithDifferentPlace ||
      propsChangedSamePlaceDifferentHours

    if (placeRemovedFromProps) {
      updHours = cloneDeep(DEFAULT_HOURS)
      updHoursClosed = cloneDeep(DEFAULT_CLOSED)
      this.setState({
        hours: updHours,
        useHours: false,
        hoursClosed: updHoursClosed
      })
    } else if (statePlaceUpdNeeded) {
      if (!this.props.place.hours) {
        updHours = cloneDeep(DEFAULT_HOURS)
        updHoursClosed = cloneDeep(DEFAULT_CLOSED)
        this.setState({
          hours: updHours,
          useHours: false,
          hoursClosed: updHoursClosed
        })
      } else {
        updHours = cloneDeep(JSON.parse(this.props.place.hours))
        let hoursKeys = Object.keys(updHours)
        fillEmptyDaysAndSlots(hoursKeys, updHours, updHoursClosed)
        padSingleDigitHours(updHours)
        this.setState({
          hours: updHours,
          useHours: true,
          hoursClosed: updHoursClosed
        })
      }
    }
  }

  handleClosedChange(hourkey) {
    let newValue = !this.state.hoursClosed[hourkey]
    const newHoursClosed = cloneDeep(this.state.hoursClosed)
    const newHours = cloneDeep(this.state.hours)
    newHoursClosed[hourkey] = newValue
    if (newValue) {
      newHours[hourkey] = [
        ["", ""],
        ["", ""],
        ["", ""]
      ]
    }
    let hoursValid = this.validateSlots(newHours)
    this.setState({ hoursClosed: newHoursClosed, hours: newHours, hoursValid })
  }

  handleUseHoursChange() {
    this.setState({
      useHours: !this.state.useHours
    })
  }

  handleHourChange(newHour, day, slot, slotIndex) {
    const updHours = cloneDeep(this.state.hours)
    updHours[day][slot][slotIndex] = newHour
    let hoursValid = this.validateSlots(updHours)
    this.setState({ hours: updHours, hoursValid })
  }

  validateSlots(hours) {
    for (var i = 0; i < HOURS_DAYS.length; i++) {
      let tslots = hours[HOURS_DAYS[i]]

      for (let index = 0; index < tslots.length; index++) {
        //if there's an empty time input in previous slots
        if (index > 0) {
          for (let prevIdx = index - 1; prevIdx >= 0; prevIdx--) {
            const incompletePreviousTimeSlot =
              !tslots[prevIdx][0] || !tslots[prevIdx][1]
            const userEditedCurrentTimeSlot =
              tslots[index][0] || tslots[index][1]
            const editedTimeSlotWhilePrevIncomplete =
              incompletePreviousTimeSlot && userEditedCurrentTimeSlot
            if (editedTimeSlotWhilePrevIncomplete) return false
          }
        }
        //if open time is after close time
        const completeTimeSlot = tslots[index][0] && tslots[index][1]
        if (completeTimeSlot) {
          let start = tslots[index][0].split(":")
          let end = tslots[index][1].split(":")
          let startTime = moment().hours(start[0]).minutes(start[1])
          let endTime = moment().hours(end[0]).minutes(end[1])
          if (startTime.isAfter(endTime)) return false
        }
      }
    }
    return true
  }

  handleSubmit(event) {
    this.setState({ isSaving: true })

    event.preventDefault()

    let hoursJSON = null
    //if hours override is enabled
    if (this.state.useHours) {
      //if invalid timeslots don't submit
      if (!this.state.hoursValid) {
        this.setState({ isSaving: false })
        alert("Invalid time for hours override!")
        return
      }
      //if invalid timeslots (previous timeslot not completely filled) don't submit
      let hoursKeys = Object.keys(this.state.hours)
      for (let index = 0; index < hoursKeys.length; index++) {
        for (let j = 0; j < this.state.hours[hoursKeys[index]].length; j++) {
          const openingTimeMissing =
            !this.state.hours[hoursKeys[index]][j][0] &&
            this.state.hours[hoursKeys[index]][j][1]
          const closingTimeMissing =
            !this.state.hours[hoursKeys[index]][j][1] &&
            this.state.hours[hoursKeys[index]][j][0]
          const incompleteTimeSlot = openingTimeMissing || closingTimeMissing
          if (incompleteTimeSlot) {
            this.setState({ isSaving: false })
            alert("Invalid time for hours override!")
            return
          }
        }
      }

      const clonedHours = cloneDeep(this.state.hours)
      //remove days with empty time slots
      hoursKeys.map((hk) => {
        let newTslot = removeEmptyTimeSlots(clonedHours[hk])
        if (newTslot.length === 0) {
          delete clonedHours[hk]
        } else {
          clonedHours[hk] = newTslot
        }
        return hk
      })

      hoursJSON = JSON.stringify(clonedHours)
    }

    const formData = new FormData(event.target)

    var uploadImage = Promise.resolve()

    if (this.uploadImageInput.files[0]) {
      const uploadImageData = new FormData()
      uploadImageData.append("image", this.uploadImageInput.files[0])
      uploadImage = Request.post("images", uploadImageData).then((res) => {
        return res.data.result.image.id
      })
    }

    uploadImage
      .then((imageId) => {
        var placeObject = {
          id: formData.get("id"),
          merchantId: this.props.merchant.id,
          name: formData.get("name"),
          type: formData.get("type"),
          regionId: formData.get("regionId"),
          factualPlaceId: formData.get("factualPlaceId").trim() || null,
          keywords: formData.get("keywords").trim() || null,
          tags: formData.get("tags").trim() || null,
          hours: hoursJSON,
          //hours: formData.get('hours').trim() || null,
          hoursDisplay: formData.get("hoursDisplay").trim() || null,
          emburseMerchantId: formData.get("emburseMerchantId").trim() || null,
          emburseMerchantName:
            formData.get("emburseMerchantName").trim() || null,
          stripeCustomerId: formData.get("stripeCustomerId").trim() || null,
          merchantServiceFeePercent:
            formData.get("merchantServiceFeePercent").trim() || null,
          customerServiceFeePercent:
            formData.get("customerServiceFeePercent").trim() || null,
          billingInterval: formData.get("billingInterval").trim() || null,
          billingEmail: formData.get("billingEmail").trim() || null,
          customDateLabel: formData.get("customDateLabel").trim() || null
        }

        if (imageId) {
          placeObject.imageId = imageId
        }

        // Start Billing Date

        const startBillingAtDate = formData.get("startBillingAtDate")
        const startBillingAtTime = formData.get("startBillingAtTime")

        if (startBillingAtDate && startBillingAtTime) {
          const startBillingAtDateString = moment(
            startBillingAtDate + " " + startBillingAtTime
          )
            .utcOffset(0)
            .format("YYYY-MM-DD HH:mm")
          placeObject["startBillingAt"] = startBillingAtDateString
        } else {
          placeObject["startBillingAt"] = null
        }

        // Custom Billing Date

        const customDate = formData.get("customDate")

        if (customDate) {
          const customDateString = moment(customDate + " 00:00")
            .utcOffset(0)
            // the time is required to properly convert timezones
            .format("YYYY-MM-DD HH:mm")
          placeObject["customDate"] = customDateString
        } else {
          placeObject["customDate"] = null
        }

        return this.props.onSave(placeObject)
      })
      .then(() => {
        this.setState({ isSaving: false })
      })
      .catch((err) => {
        alert(err.response ? err.response.data.context.message : err)
        this.setState({ isSaving: false })
      })
  }

  checkTimeslotDisabled(idx, hourKey) {
    if (this.state.hoursClosed[hourKey]) {
      return true
    }
    if (idx === 0) return false
    for (let index = idx - 1; index >= 0; index--) {
      const incompleteTimeSlot =
        !this.state.hours[hourKey][index][0] ||
        !this.state.hours[hourKey][index][1]
      if (incompleteTimeSlot) {
        return true
      }
    }
    return false
  }

  render() {
    return (
      <Modal size="lg" isOpen={this.props.isOpen}>
        <Form onSubmit={this.handleSubmit}>
          <Input
            type="hidden"
            name="id"
            value={this.props.place ? this.props.place.id : ""}
          />
          <ModalHeader>
            {this.props.place
              ? "Edit Place"
              : "New Place for " +
                (this.props.merchant ? this.props.merchant.name : "")}
          </ModalHeader>
          <ModalBody>
            <Row>
              <Col xs="8">
                <FormGroup>
                  <Label>
                    Name<sup>*</sup>
                  </Label>
                  <Input
                    type="text"
                    name="name"
                    defaultValue={this.props.place ? this.props.place.name : ""}
                  />
                </FormGroup>
              </Col>
              <Col xs="4">
                <FormGroup>
                  <Label>
                    Region ID<sup>*</sup>
                  </Label>
                  <Input
                    type="text"
                    name="regionId"
                    defaultValue={
                      this.props.place ? this.props.place.regionId : ""
                    }
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs="12">
                <FormGroup>
                  <Label>Factual Place ID</Label>
                  <Input
                    type="text"
                    name="factualPlaceId"
                    defaultValue={
                      this.props.place ? this.props.place.factualPlaceId : ""
                    }
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs="4">
                <Label>
                  Type<sup>*</sup>
                </Label>
                <Input
                  type="select"
                  name="type"
                  defaultValue={this.props.place ? this.props.place.type : ""}
                >
                  <option value="premium">Premium</option>
                  <option value="partner">Partner</option>
                  <option value="basic">Basic</option>
                  <option value="nonPartner">Non-Partner</option>
                  <option value="archived">Archived</option>
                </Input>
              </Col>
              <Col xs="8">
                <FormGroup>
                  <Label>
                    Replace Image (current image:{" "}
                    {this.props.place ? this.props.place.imageId : "none"})
                  </Label>
                  <Input
                    type="file"
                    name="image"
                    innerRef={(ref) => {
                      this.uploadImageInput = ref
                    }}
                  />
                </FormGroup>
              </Col>
            </Row>
            <FormGroup>
              <Label>Keywords</Label>
              <Input
                type="text"
                name="keywords"
                defaultValue={this.props.place ? this.props.place.keywords : ""}
              />
            </FormGroup>
            <FormGroup>
              <Label>Tags</Label>
              <Input
                type="text"
                name="tags"
                defaultValue={this.props.place ? this.props.place.tags : ""}
              />
            </FormGroup>
            <FormGroup check style={{ marginBottom: 10 }}>
              <Label check>
                <Input
                  type="checkbox"
                  id="hoursoverride"
                  checked={this.state.useHours}
                  onChange={() => this.handleUseHoursChange()}
                />{" "}
                Hours Override
              </Label>
            </FormGroup>
            {this.state.useHours && (
              <FormGroup>
                {!this.state.hoursValid && (
                  <Alert color="danger">Invalid time!</Alert>
                )}
                <Table size="sm" striped bordered hover responsive>
                  <thead>
                    <tr>
                      <th>Day</th>
                      <th>Time slot 1</th>
                      <th>Time slot 2</th>
                      <th>Time slot 3</th>
                      <th>Closed</th>
                    </tr>
                  </thead>
                  <tbody>
                    {HOURS_DAYS.map((day, i) => (
                      <tr key={i}>
                        <th scope="row">
                          {day.charAt(0).toUpperCase() + day.slice(1)}
                        </th>
                        {this.state.hours[day].map((timeslot, j) => (
                          <td key={j}>
                            <div
                              style={{
                                display: "flex",
                                flexFlow: "row wrap",
                                alignItems: "center"
                              }}
                            >
                              <FormGroup className="mr-sm-2">
                                <Input
                                  type="time"
                                  disabled={this.checkTimeslotDisabled(j, day)}
                                  name={`ts${day}${i}${j}`}
                                  value={timeslot[0]}
                                  onChange={(e) => {
                                    this.handleHourChange(
                                      e.target.value,
                                      day,
                                      j,
                                      0
                                    )
                                  }}
                                />
                              </FormGroup>

                              <FormGroup className="mr-sm-2">
                                <Input
                                  type="time"
                                  disabled={this.checkTimeslotDisabled(j, day)}
                                  name={`ts${day}${i}${j}`}
                                  value={timeslot[1]}
                                  onChange={(e) => {
                                    this.handleHourChange(
                                      e.target.value,
                                      day,
                                      j,
                                      1
                                    )
                                  }}
                                />
                              </FormGroup>
                            </div>
                          </td>
                        ))}
                        <td>
                          <div style={{ height: 30, width: 30, padding: 20 }}>
                            <Input
                              type="checkbox"
                              id={`closed${day}`}
                              style={{ position: "relative", margin: 0 }}
                              onChange={() => this.handleClosedChange(day)}
                              checked={this.state.hoursClosed[day]}
                            />
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </FormGroup>
            )}
            {/*<FormGroup>
              <Label>Hours Display Override</Label>
            <FormGroup>
              <Label>Hours</Label>
              <Input type="text" name="hours" defaultValue={this.props.place ? this.props.place.hours : ''} />
            </FormGroup>*/}
            <FormGroup>
              <Label>Hours Display</Label>
              <Input
                type="text"
                name="hoursDisplay"
                defaultValue={
                  this.props.place ? this.props.place.hoursDisplay : ""
                }
              />
            </FormGroup>
            <Row>
              <Col xs="6">
                <FormGroup>
                  <Label>Emburse Merchant ID</Label>
                  <Input
                    type="text"
                    name="emburseMerchantId"
                    defaultValue={
                      this.props.place ? this.props.place.emburseMerchantId : ""
                    }
                  />
                </FormGroup>
              </Col>
              <Col xs="6">
                <FormGroup>
                  <Label>Emburse Merchant Name</Label>
                  <Input
                    type="text"
                    name="emburseMerchantName"
                    defaultValue={
                      this.props.place
                        ? this.props.place.emburseMerchantName
                        : ""
                    }
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs="6">
                <FormGroup>
                  <Label>Stripe Customer ID</Label>
                  <Input
                    type="text"
                    name="stripeCustomerId"
                    defaultValue={
                      this.props.place ? this.props.place.stripeCustomerId : ""
                    }
                  />
                </FormGroup>
              </Col>
              <Col xs="6">
                <FormGroup>
                  <Label>Billing Email</Label>
                  <Input
                    type="text"
                    name="billingEmail"
                    defaultValue={
                      this.props.place ? this.props.place.billingEmail : ""
                    }
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs="6">
                <FormGroup>
                  <Label>Customer Service Fee % - Region Default 20%</Label>
                  <Input
                    defaultValue={
                      this.props.place
                        ? this.props.place.customerServiceFeePercent
                        : null
                    }
                    max="100"
                    min="0"
                    name="customerServiceFeePercent"
                    step="1"
                    type="number"
                  />
                </FormGroup>
              </Col>
              <Col xs="6">
                <FormGroup>
                  <Label>Merchant Service Fee % - Region Default 0%</Label>
                  <Input
                    defaultValue={
                      this.props.place
                        ? this.props.place.merchantServiceFeePercent
                        : null
                    }
                    max="100"
                    min="0"
                    name="merchantServiceFeePercent"
                    step="1"
                    type="number"
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs="5">
                <FormGroup>
                  <Label>Start Billing Date</Label>
                  <Input
                    type="date"
                    name="startBillingAtDate"
                    defaultValue={
                      this.props.place && this.props.place.startBillingAt
                        ? moment(this.props.place.startBillingAt).format(
                            "YYYY-MM-DD"
                          )
                        : ""
                    }
                  />
                </FormGroup>
              </Col>
              <Col xs="3">
                <FormGroup>
                  <Label>Time</Label>
                  <Input
                    type="time"
                    name="startBillingAtTime"
                    defaultValue={
                      this.props.place && this.props.place.startBillingAt
                        ? moment(this.props.place.startBillingAt).format(
                            "HH:mm"
                          )
                        : ""
                    }
                  />
                </FormGroup>
              </Col>
              <Col xs="4">
                <FormGroup>
                  <Label>Billing</Label>
                  <Input
                    type="select"
                    name="billingInterval"
                    defaultValue={
                      this.props.place ? this.props.place.billingInterval : 0
                    }
                  >
                    <option value="0">Off</option>
                    <option value="86400">Daily</option>
                    <option value="604800">Weekly</option>
                  </Input>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs="5">
                <FormGroup>
                  <Label>Custom Date</Label>
                  <Input
                    type="date"
                    name="customDate"
                    defaultValue={
                      this.props.place && this.props.place.customDate
                        ? moment(this.props.place.customDate).format(
                            "YYYY-MM-DD"
                          )
                        : ""
                    }
                  />
                </FormGroup>
              </Col>
              <Col xs="7">
                <FormGroup>
                  <Label>Custom Date Label</Label>
                  <Input
                    type="text"
                    name="customDateLabel"
                    defaultValue={
                      this.props.place ? this.props.place.customDateLabel : ""
                    }
                  />
                </FormGroup>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <FormButtons
              isSaving={this.state.isSaving}
              onCancel={this.props.onCancel}
            >
              {this.props.place ? "Save" : "Create Place"}
            </FormButtons>
          </ModalFooter>
        </Form>
      </Modal>
    )
  }
}
