/* eslint-disable max-statements, react/require-optimization, vars-on-top, no-var, init-declarations, no-magic-numbers, react/destructuring-assignment, prefer-destructuring, camelcase, no-dupe-else-if, object-shorthand, react/no-set-state, no-alert, no-ternary, lines-between-class-members, prefer-template, max-lines-per-function, react/jsx-handler-names, no-nested-ternary, react/jsx-no-bind, max-lines, react/no-access-state-in-setstate, class-methods-use-this, react/jsx-max-depth, no-param-reassign, id-length */

import {
  Col,
  Container,
  Input,
  InputGroup,
  InputGroupAddon,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table
} from "reactstrap"
import AdminForm from "./AdminForm"
import { Button } from "@fetch/ui"
import LoadingIndicator from "./LoadingIndicator"
import React from "react"
import Request from "../lib/Request"
import SearchBar from "./SearchBar"
import UserManager from "../lib/UserManager"
import UserNotifications from "./UserNotifications"
import UserPermissions from "./UserPermissions"
import handleError from "../lib/ErrorHandler"
import styled from "styled-components"

require("../lib/Extensions")

const Buttons = styled.td`
  column-gap: 10px;
  display: grid;
  grid-template-columns: auto auto;
  row-gap: 10px;
`

export default class Admins extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      admin: null,
      admins: [],
      editAdmin: false,
      // eslint-disable-next-line react/no-unused-state
      editUserNotfications: false,
      editUserPermissions: false,
      isLoading: false,
      newAdmin: false,
      offset: 0,
      pageSize: 10,
      query: null
    }

    this.reloadData = this.reloadData.bind(this)
    this.search = this.search.bind(this)

    this.newAdmin = this.newAdmin.bind(this)
    this.cancelNewAdmin = this.cancelNewAdmin.bind(this)
    this.saveNewAdmin = this.saveNewAdmin.bind(this)

    this.editAdmin = this.editAdmin.bind(this)
    this.cancelEditAdmin = this.cancelEditAdmin.bind(this)
    this.saveEditAdmin = this.saveEditAdmin.bind(this)

    this.editUserPermissions = this.editUserPermissions.bind(this)
    this.closeUserPermissions = this.closeUserPermissions.bind(this)

    this.editUserNotifications = this.editUserNotifications.bind(this)
    this.closeUserNotifications = this.closeUserNotifications.bind(this)
  }

  componentDidMount() {
    this.reloadData()
  }

  loadData(offset, query) {
    this.setState({ isLoading: true })

    if (!offset) {
      offset = 0
    }

    return UserManager.getAdmins(offset, this.state.pageSize, query)
      .then((admins) => {
        this.setState({
          admins: admins,
          isLoading: false,
          offset: offset || 0,
          query: query
        })
      })
      .catch((err) => {
        handleError(err)
        this.setState({ isLoading: false })
      })
  }

  reloadData() {
    this.loadData(this.state.offset, this.state.query)
  }

  newAdmin() {
    this.setState({ newAdmin: true })
  }

  cancelNewAdmin() {
    this.setState({ newAdmin: false })
  }

  saveNewAdmin(data) {
    Request.post("admins", data)
      .then(() => {
        this.setState({ newAdmin: false })
        this.reloadData()
      })
      .catch((err) => {
        alert(err.response ? err.response.data.context.message : err)
      })
  }

  editAdmin(admin) {
    this.setState({ admin: admin, editAdmin: true })
  }

  cancelEditAdmin() {
    this.setState({ editAdmin: false })
  }

  saveEditAdmin(data) {
    Request.post("users/" + data.id, data)
      .then(() => {
        this.setState({ editAdmin: false })
        this.reloadData()
      })
      .catch((err) => {
        alert(err.response ? err.response.data.context.message : err)
      })
  }

  deleteAdmin(admin) {
    if (window.confirm("Are you sure you want to delete this admin?")) {
      Request.post("users/" + admin.id + "/delete")
        .then(() => {
          this.reloadData()
        })
        .catch((err) => {
          alert(err.response ? err.response.data.context.message : err)
        })
    }
  }

  editUserPermissions(admin) {
    this.setState({ admin: admin, editUserPermissions: true })
  }

  closeUserPermissions() {
    this.setState({ editUserPermissions: false })
  }

  editUserNotifications(admin) {
    this.setState({ admin: admin, editUserNotifications: true })
  }

  closeUserNotifications() {
    this.setState({ editUserNotifications: false })
  }

  search(searchTerm) {
    this.loadData(0, searchTerm.trim())
  }

  nextPage() {
    this.loadData(this.state.offset + this.state.pageSize, this.state.query)
  }

  previousPage() {
    const offset = Math.max(this.state.offset - this.state.pageSize, 0)
    this.loadData(offset, this.state.query)
  }

  changePageSize(newPageSize) {
    this.setState({ pageSize: newPageSize })
    this.loadData(0, this.state.query)
  }

  render() {
    return (
      <div>
        <AdminForm
          cancel={this.cancelNewAdmin}
          isOpen={this.state.newAdmin}
          save={this.saveNewAdmin}
        />
        <AdminForm
          admin={this.state.admin}
          cancel={this.cancelEditAdmin}
          isOpen={this.state.editAdmin}
          save={this.saveEditAdmin}
        />
        <Container fluid>
          <Row>
            <Col xs="3">
              <h1>
                {"Admins"}{" "}
                {this.state.query && 'for "' + this.state.query + '"'}
                <LoadingIndicator isLoading={this.state.isLoading} />
              </h1>
            </Col>
            <Col className="d-flex align-items-center" xs="3">
              <SearchBar
                onClear={() => this.loadData(0, "")}
                onSearch={this.search}
                query={this.state.query}
              />
            </Col>
            <Col className="d-flex align-items-center" xs="4">
              <Pagination>
                <PaginationItem disabled={this.state.offset === 0}>
                  <PaginationLink
                    href="#"
                    onClick={() => this.previousPage()}
                    previous
                  />
                </PaginationItem>
                <PaginationItem>
                  <PaginationLink>
                    {Math.round(this.state.offset / this.state.pageSize) + 1}
                  </PaginationLink>
                </PaginationItem>
                <PaginationItem
                  disabled={this.state.admins.length < this.state.pageSize}
                >
                  <PaginationLink
                    href="#"
                    next
                    onClick={() => this.nextPage()}
                  />
                </PaginationItem>
              </Pagination>
              &nbsp;
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  {"Page Size: "}
                </InputGroupAddon>
                <Input
                  name="pageSize"
                  onChange={(e) => this.changePageSize(`${e.target.value}`)}
                  type="select"
                >
                  <option>{"10"}</option>
                  <option>{"50"}</option>
                  <option>{"200"}</option>
                  <option>{"1000"}</option>
                </Input>
              </InputGroup>
            </Col>
            <Col xs="2">
              <Button
                label="+ New Admin"
                onClick={this.newAdmin}
                outlined
                secondary
                small
              />
            </Col>
          </Row>
        </Container>

        <UserPermissions
          isOpen={this.state.editUserPermissions}
          onChange={this.reloadData}
          onClose={this.closeUserPermissions}
          user={this.state.admin}
        />
        <UserNotifications
          isOpen={this.state.editUserNotifications}
          onChange={this.reloadData}
          onClose={this.closeUserNotifications}
          user={this.state.admin}
        />
        <Table bordered responsive striped>
          <thead>
            <tr>
              <th>{"Actions"}</th>
              <th>{"Id"}</th>
              <th>{"Name"}</th>
              <th>{"Email"}</th>
              <th>{"Phone"}</th>
              <th>{"Permissions"}</th>
              <th>{"Notifications"}</th>
            </tr>
          </thead>
          <tbody>
            {this.state.admins.map((admin) => (
              <tr key={admin.id}>
                <Buttons>
                  <Button
                    label="Edit"
                    onClick={() => this.editAdmin(admin)}
                    outlined
                    secondary
                    small
                  />

                  <Button
                    label="Delete"
                    onClick={() => this.deleteAdmin(admin)}
                    secondary
                    small
                  />
                  <Button
                    label="Permissions"
                    onClick={() => this.editUserPermissions(admin)}
                    outlined
                    small
                  />

                  <Button
                    label="Notifications"
                    onClick={() => this.editUserNotifications(admin)}
                    outlined
                    small
                  />
                </Buttons>
                <td>{admin.id}</td>
                <td>{admin.firstName + " " + admin.lastName}</td>
                <td>{admin.email}</td>
                <td>{admin.phone}</td>
                <td>
                  <ul>
                    {admin.permissions.map((permission) => (
                      <li key={permission}>{permission.type}</li>
                    ))}
                  </ul>
                </td>
                <td>
                  <ul>
                    {admin.notifications.map((notification) => (
                      <li key={notification.type}>{notification.type}</li>
                    ))}
                  </ul>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    )
  }
}
