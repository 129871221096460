import {
  Container,
  Row,
  Col,
  Table,
  Breadcrumb,
  BreadcrumbItem
} from "reactstrap"
import { Button } from "@fetch/ui"
import React from "react"
import Request from "../lib/Request"

require("../lib/Extensions")

export default class PlaceInvoices extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      merchant: null,
      place: null,
      placeInvoices: []
    }

    this.loadData = this.loadData.bind(this)
  }

  componentDidMount() {
    this.loadData()
  }

  loadData() {
    const getPlace = Request.get("places/" + this.props.match.params.place_id)
    const getPlaceInvoices = Request.get(
      "place_invoices?place_id=" + this.props.match.params.place_id
    )
    Promise.all([getPlace, getPlaceInvoices])
      .then((values) => {
        const [placeRes, placeInvoicesRes] = values

        const place = placeRes.data.result.place
        const placeInvoices = placeInvoicesRes.data.result.placeInvoices

        return Request.get("merchants/" + place.merchantId).then(
          (merchantRes) => {
            this.setState({
              merchant: merchantRes.data.result.merchant,
              place: place,
              placeInvoices: placeInvoices
            })
          }
        )
      })
      .catch((err) => {
        console.log(err)
      })
  }

  send(placeInvoice) {
    var email = prompt("Enter an email address")
    if (!email) {
      return
    }

    Request.post(`place_invoices/${placeInvoice.id}/send`, { email: email })
      .then((res) => {
        window.alert("Invoice sent")
      })
      .catch((err) => {
        alert(err.response ? err.response.data.context.message : err)
      })
  }

  render() {
    return (
      <div>
        <Breadcrumb>
          <BreadcrumbItem>
            <a href="/merchants">Merchants</a>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <a
              href={
                this.state.merchant
                  ? "/merchants/" + this.state.merchant.id
                  : ""
              }
            >
              {this.state.merchant && this.state.merchant.name}
            </a>
          </BreadcrumbItem>
          <BreadcrumbItem>
            Invoices for {this.state.place && this.state.place.name}
          </BreadcrumbItem>
        </Breadcrumb>
        <Container fluid>
          <Row>
            <Col xs="12">
              <h1>
                Invoices for {this.state.place ? this.state.place.name : ""}
              </h1>
            </Col>
          </Row>
        </Container>
        <Table striped bordered responsive>
          <thead>
            <tr>
              <th>Actions</th>
              <th>Id</th>
              <th>Status</th>
              <th>Purchase Total</th>
              <th>Coupon Reimbursement Total</th>
              <th>Revenue Share Total</th>
              <th>Total Charge</th>
            </tr>
          </thead>
          <tbody>
            {this.state.placeInvoices.map((placeInvoice) => (
              <tr>
                <td>
                  <Button
                    label="Send"
                    onClick={() => this.send(placeInvoice)}
                    outlined
                    secondary
                    small
                  />
                </td>
                <td>{placeInvoice.id}</td>
                <td>{placeInvoice.status}</td>
                <td>{placeInvoice.purchaseTotal.formatDollars()}</td>
                <td>
                  {placeInvoice.couponReimbursementTotal &&
                    placeInvoice.couponReimbursementTotal.formatDollars()}
                </td>
                <td>
                  {placeInvoice.revenueShareTotal &&
                    placeInvoice.revenueShareTotal.formatDollars()}
                </td>
                <td>{placeInvoice.totalCharge.formatDollars()}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    )
  }
}
