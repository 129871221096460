/* eslint-disable prefer-template, no-ternary */

import Constants from "../lib/Constants"
import axios from "axios"

export default class Request {
  static currentUser() {
    return JSON.parse(sessionStorage.getItem("currentUser"))
  }

  static headers() {
    const headers = {}

    if (this.currentUser() && this.currentUser().jwt) {
      headers.Authorization = "Bearer " + this.currentUser().jwt
    }

    return headers
  }

  static get(url) {
    return axios.get(
      process.env.REACT_APP_BASE_URL +
        "/v1.0/" +
        url +
        (url.includes("?") ? "&" : "?") +
        "platform=portal&client_version=" +
        Constants.Version,
      { headers: this.headers() }
    )
  }

  static post(url, data) {
    return axios.post(
      process.env.REACT_APP_BASE_URL +
        "/v1.0/" +
        url +
        (url.includes("?") ? "&" : "?") +
        "platform=portal&client_version=" +
        Constants.Version,
      data,
      { headers: this.headers() }
    )
  }

  static getCustomResponseType(url, type) {
    return axios.get(
      process.env.REACT_APP_BASE_URL +
        "/v1.0/" +
        url +
        (url.includes("?") ? "&" : "?") +
        "platform=portal&client_version=" +
        Constants.Version,
      { headers: this.headers(), responseType: type }
    )
  }
}
