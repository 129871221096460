import React from 'react'
import { Container, Row, Col, Alert } from 'reactstrap'
import QueryString from 'querystring'
import LoadingIndicator from './LoadingIndicator'

import Request from '../lib/Request'

export default class StripeConnect extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isLoading: false,
      error: null
    }
  }

  componentDidMount() {
    this.connectStripeExpressAccount()
  }

  connectStripeExpressAccount() {
    this.setState({ isLoading: true })

    const queryString = this.props.location.search.slice(1)
    const code = QueryString.parse(queryString).code

    Request.post('drivers/connect_stripe_express_account', { code: code }).then(connectStripeExpressAccountRes => {
      this.setState({ isLoading: false })
    }).catch(err => {
      const errorMessage = err.response ? err.response.data.context.message : err
      alert(errorMessage)
      this.setState({ isLoading: false, error: errorMessage })
    })
  }

  render() {
    return (
      <div>
        <Container fluid>
          <Row>
            <Col xs="12">
              {
                this.state.isLoading ? <h1>Connecting Your Account <LoadingIndicator isLoading /></h1> : 
                  this.state.error ? <p>Error: {this.state.error}</p> :
                    <div>
                      <Alert color="success">Connected!</Alert>
                      <a href="/">Go to the Dashboard</a>
                    </div>
              }
            </Col>
          </Row>
        </Container>
      </div>
    )
  }
}
