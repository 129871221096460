// @flow
/* eslint-disable no-ternary, react/jsx-no-bind, max-lines-per-function, react/no-multi-comp */

import { Redirect, Route, Switch } from "react-router-dom"
import AdminDashboard from "./components/AdminDashboard"
import Admins from "./components/Admins"
import Constants from "./lib/Constants"
import Coupons from "./components/Coupons"
import type { CurrentUser } from "@fetch/portal"
import Customers from "./components/Customers"
import Driver from "./components/Driver"
import DriverDashboard from "./components/DriverDashboard"
import Drivers from "./components/Drivers"
import Fetch from "./components/Fetch"
import Fetches from "./components/Fetches"
import Login from "./components/Login"
import Logout from "./components/Logout"
import MenuItemOptionGroups from "./components/MenuItemOptionGroups"
import MenuItemOptions from "./components/MenuItemOptions"
import MenuItems from "./components/MenuItems"
import Merchants from "./components/Merchants"
import NotFound from "./components/NotFound"
import PlaceInvoices from "./components/PlaceInvoices"
import React from "react"
import Regions from "./components/Regions"
import StripeConnect from "./components/StripeConnect"
import ViewMerchant from "./components/ViewMerchant"

const PrivateRoute = ({ component: Component, currentUser, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      currentUser ? (
        <Component {...props} currentUser={currentUser} />
      ) : (
        <Redirect
          to={{ pathname: "/login", state: { from: props.location } }}
        />
      )
    }
  />
)

type Props = {|
  +currentUser: ?CurrentUser
|}

const Routes = ({ currentUser }: Props) => {
  if (!currentUser) {
    return (
      <Switch>
        <Route component={Login} exact path="/" />
        <Route component={Login} exact path="/login" />
        <Route component={Logout} exact path="/logout" />
        <Route component={Driver} exact path="/drivers/:id" />
        <Route component={NotFound} />
      </Switch>
    )
  }

  if (currentUser.user.type === Constants.UserType.driver) {
    return (
      <Switch>
        <Route component={Login} exact path="/login" />
        <Route component={Logout} exact path="/logout" />
        <Route component={StripeConnect} exact path="/stripe_connect" />
        <PrivateRoute
          component={DriverDashboard}
          currentUser={currentUser}
          exact
          path="/"
        />
        <Route component={NotFound} />
      </Switch>
    )
  } else if (currentUser.user.type === Constants.UserType.admin) {
    return (
      <Switch>
        <Route component={Login} exact path="/login" />
        <Route component={Logout} exact path="/logout" />
        <PrivateRoute
          component={AdminDashboard}
          currentUser={currentUser}
          exact
          path="/"
        />
        <PrivateRoute
          component={Coupons}
          currentUser={currentUser}
          exact
          path="/coupons"
        />
        <PrivateRoute
          component={Fetches}
          currentUser={currentUser}
          exact
          path="/orders"
        />
        <PrivateRoute
          component={Fetch}
          currentUser={currentUser}
          path="/orders/:id"
        />
        <PrivateRoute
          component={Merchants}
          currentUser={currentUser}
          exact
          path="/merchants"
        />
        <PrivateRoute
          component={ViewMerchant}
          currentUser={currentUser}
          exact
          path="/merchants/:merchant_id"
        />
        <PrivateRoute
          component={MenuItems}
          currentUser={currentUser}
          exact
          path="/merchants/:merchant_id/menus/:menu_id/items"
        />
        <PrivateRoute
          component={MenuItemOptionGroups}
          currentUser={currentUser}
          exact
          path="/merchants/:merchant_id/menus/:menu_id/items/:menu_item_id/option_groups"
        />
        <PrivateRoute
          component={MenuItemOptions}
          currentUser={currentUser}
          exact
          path="/merchants/:merchant_id/menus/:menu_id/items/:menu_item_id/option_groups/:menu_item_option_group_id/options"
        />
        <PrivateRoute
          component={PlaceInvoices}
          currentUser={currentUser}
          exact
          path="/places/:place_id/invoices"
        />
        <PrivateRoute
          component={Customers}
          currentUser={currentUser}
          exact
          path="/customers"
        />
        <PrivateRoute
          component={Drivers}
          currentUser={currentUser}
          exact
          path="/drivers"
        />
        <PrivateRoute
          component={Admins}
          currentUser={currentUser}
          exact
          path="/admins"
        />
        <PrivateRoute
          component={Regions}
          currentUser={currentUser}
          exact
          path="/regions"
        />
        <Route component={NotFound} />
      </Switch>
    )
  }

  return null
}

export default Routes
