/* eslint-disable max-statements, react/require-optimization, vars-on-top, no-var, init-declarations, no-magic-numbers, react/destructuring-assignment, prefer-destructuring, camelcase, no-dupe-else-if, object-shorthand, react/no-set-state, no-alert, no-ternary, lines-between-class-members, prefer-template, max-lines-per-function, react/jsx-handler-names, no-nested-ternary, react/jsx-no-bind, max-lines, react/no-access-state-in-setstate, class-methods-use-this, react/jsx-max-depth, no-param-reassign, id-length */

import { Col, Container, Row, Table } from "reactstrap"
import { Button } from "@fetch/ui"
import LoadingIndicator from "./LoadingIndicator"
import MerchantForm from "./MerchantForm"
import React from "react"
import Request from "../lib/Request"
import styled from "styled-components"

require("../lib/Extensions")

const Buttons = styled.td`
  column-gap: 10px;
  display: grid;
  grid-template-columns: auto auto;
`

export default class Merchants extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      editMerchant: false,
      isLoading: false,
      merchant: null,
      merchants: [],
      newMerchant: false
    }

    this.loadData = this.loadData.bind(this)

    this.newMerchant = this.newMerchant.bind(this)
    this.cancelNewMerchant = this.cancelNewMerchant.bind(this)
    this.saveNewMerchant = this.saveNewMerchant.bind(this)

    this.editMerchant = this.editMerchant.bind(this)
    this.cancelEditMerchant = this.cancelEditMerchant.bind(this)
    this.saveEditMerchant = this.saveEditMerchant.bind(this)
  }

  componentDidMount() {
    this.loadData()
  }

  loadData() {
    this.setState({ isLoading: true })

    Request.get("merchants?limit=1000")
      .then((res) => {
        const merchants = res.data.result.merchants
        this.setState({ isLoading: false, merchants: merchants })
      })
      .catch((err) => {
        alert(err.response ? err.response.data.context.message : err)
        this.setState({ isLoading: false })
      })
  }

  newMerchant() {
    this.setState({ newMerchant: true })
  }
  cancelNewMerchant() {
    this.setState({ newMerchant: false })
  }
  saveNewMerchant(data) {
    Request.post("merchants", data)
      .then(() => {
        this.setState({ newMerchant: false })
        this.loadData()
      })
      .catch((err) => {
        alert(err.response ? err.response.data.context.message : err)
      })
  }

  editMerchant(merchant) {
    this.setState({ editMerchant: true, merchant: merchant })
  }
  cancelEditMerchant() {
    this.setState({ editMerchant: false })
  }
  saveEditMerchant(data) {
    Request.post("merchants/" + data.id, data)
      .then(() => {
        this.setState({ editMerchant: false })
        this.loadData()
      })
      .catch((err) => {
        alert(err.response ? err.response.data.context.message : err)
      })
  }
  navigateToViewMerchantForMerchant(merchantId) {
    window.location.href = `/merchants/${merchantId}`
  }

  render() {
    return (
      <div>
        <Container fluid>
          <Row>
            <Col xs="8">
              <h1>
                {"Merchants "}{" "}
                <LoadingIndicator isLoading={this.state.isLoading} />
              </h1>
            </Col>
            <Col className="d-flex align-items-center" xs="4">
              <Button
                label="+ New Merchant"
                onClick={this.newMerchant}
                outlined
                secondary
                small
              />
            </Col>
          </Row>
        </Container>
        <MerchantForm
          isOpen={this.state.newMerchant}
          onCancel={this.cancelNewMerchant}
          onSave={this.saveNewMerchant}
        />
        <MerchantForm
          isOpen={this.state.editMerchant}
          merchant={this.state.merchant}
          onCancel={this.cancelEditMerchant}
          onSave={this.saveEditMerchant}
        />
        <Table bordered responsive striped>
          <thead>
            <tr>
              <th width="300">{"Actions"}</th>
              <th width="80">{"Id"}</th>
              <th>{"Name"}</th>
              <th>{"User Id"}</th>
            </tr>
          </thead>
          <tbody>
            {this.state.merchants.map((merchant) => (
              <tr key={merchant.id}>
                <Buttons>
                  <Button
                    label="Edit"
                    onClick={() => this.editMerchant(merchant)}
                    outlined
                    secondary
                    small
                  />

                  <Button
                    label="Places & Menus"
                    onClick={() =>
                      this.navigateToViewMerchantForMerchant(merchant.id)
                    }
                    outlined
                    small
                  />
                </Buttons>
                <td>{merchant.id}</td>
                <td>{merchant.name}</td>
                <td>{merchant.userId}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    )
  }
}
