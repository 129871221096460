import { Form, InputGroup, InputGroupAddon, Input } from "reactstrap"
import { Button } from "@fetch/ui"
import React from "react"
import styled from "styled-components"

const ButtonWrapper = styled.div`
  & button[type="submit"],
  button[type="button"] {
    height: 38px;
  }
`

class ClearSearchButton extends React.Component {
  render() {
    if (this.props.query) {
      return (
        <ButtonWrapper>
          <Button label="Clear" onClick={this.props.onClick} outlined small />
        </ButtonWrapper>
      )
    }

    return null
  }
}

export default class SearchBar extends React.Component {
  constructor(props) {
    super(props)

    this.state = {}

    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleSubmit(event) {
    event.preventDefault()
    const formData = new FormData(event.target)
    const searchTerm = formData.get("searchTerm")
    this.props.onSearch(searchTerm)
  }

  render() {
    return (
      <div className="no-wrap">
        <Form onSubmit={this.handleSubmit}>
          <InputGroup>
            <ClearSearchButton
              onClick={this.props.onClear}
              query={this.props.query}
            />
            <Input
              type="search"
              name="searchTerm"
              placeholder="search"
              width="400"
            />
            <InputGroupAddon addonType="append">
              <ButtonWrapper>
                <Button buttonType="submit" label="Search" secondary small />
              </ButtonWrapper>
            </InputGroupAddon>
          </InputGroup>
        </Form>
      </div>
    )
  }
}
