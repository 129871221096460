import { Container, Row, Col, Table } from "reactstrap"
import { Button } from "@fetch/ui"
import React from "react"
import Request from "../lib/Request"
import styled from "styled-components"

import MenuForm from "./MenuForm"
import LoadingIndicator from "./LoadingIndicator"

require("../lib/Extensions")

const Buttons = styled.td`
  column-gap: 10px;
  display: grid;
  grid-template-columns: auto auto auto;
`

export default class Menus extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      merchant: null,
      isLoading: false,
      menus: [],
      newMenu: false,
      editMenu: false,
      menu: null
    }

    this.loadData = this.loadData.bind(this)

    this.newMenu = this.newMenu.bind(this)
    this.cancelNewMenu = this.cancelNewMenu.bind(this)
    this.saveNewMenu = this.saveNewMenu.bind(this)

    this.editMenu = this.editMenu.bind(this)
    this.cancelEditMenu = this.cancelEditMenu.bind(this)
    this.saveEditMenu = this.saveEditMenu.bind(this)

    this.deleteMenu = this.deleteMenu.bind(this)
  }

  componentDidMount() {
    this.loadData()
  }

  loadData() {
    this.setState({ isLoading: true })

    const getMerchant = Request.get("merchants/" + this.props.merchantId)
    const getMenus = Request.get("menus?merchant_id=" + this.props.merchantId)
    Promise.all([getMerchant, getMenus])
      .then((values) => {
        const [merchantRes, menusRes] = values

        const merchant = merchantRes.data.result.merchant
        const menus = menusRes.data.result.menus
        this.setState({ merchant: merchant, menus: menus, isLoading: false })
      })
      .catch((err) => {
        alert(err.response ? err.response.data.context.message : err)
        this.setState({ isLoading: false })
      })
  }

  newMenu() {
    this.setState({ newMenu: true })
  }
  cancelNewMenu() {
    this.setState({ newMenu: false })
  }
  saveNewMenu(data) {
    console.log(data)

    Request.post("menus", data)
      .then((res) => {
        this.setState({ newMenu: false })
        this.loadData()
      })
      .catch((err) => {
        alert(err.response ? err.response.data.context.message : err)
      })
  }

  editMenu(menu) {
    console.log(menu)
    this.setState({ editMenu: true, menu: menu })
  }
  cancelEditMenu() {
    this.setState({ editMenu: false })
  }
  saveEditMenu(data) {
    console.log(data)

    Request.post("menus/" + data.id, data)
      .then((res) => {
        console.log(res)
        this.setState({ editMenu: false })
        this.loadData()
      })
      .catch((err) => {
        alert(err.response ? err.response.data.context.message : err)
      })
  }

  deleteMenu(menu) {
    if (window.confirm(`Are you sure you want to delete ${menu.name}?`)) {
      Request.post("menus/" + menu.id + "/delete")
        .then((res) => {
          console.log(res)
          this.loadData()
        })
        .catch((err) => {
          alert(err.response ? err.response.data.context.message : err)
        })
    }
  }

  navigateToMenuItemsForMenu(menuId) {
    window.location.href = `/merchants/${this.state.merchant.id}/menus/${menuId}/items`
  }

  render() {
    return (
      <div>
        <Container fluid>
          <Row>
            <Col xs="8">
              <h1>
                Menus for {this.state.merchant ? this.state.merchant.name : ""}{" "}
                <LoadingIndicator isLoading={this.state.isLoading} />
              </h1>
            </Col>
            <Col xs="4" className="d-flex align-items-center">
              <Button
                label="+ New Menu"
                onClick={this.newMenu}
                outlined
                secondary
                small
              />
            </Col>
          </Row>
        </Container>
        <MenuForm
          isOpen={this.state.newMenu}
          onCancel={this.cancelNewMenu}
          onSave={this.saveNewMenu}
          merchant={this.state.merchant}
        />
        <MenuForm
          isOpen={this.state.editMenu}
          onCancel={this.cancelEditMenu}
          onSave={this.saveEditMenu}
          menu={this.state.menu}
          merchant={this.state.merchant}
        />
        <Table striped bordered responsive>
          <thead>
            <tr>
              <th width="290">Actions</th>
              <th width="80">Id</th>
              <th>Name</th>
            </tr>
          </thead>
          <tbody>
            {this.state.menus.map((menu) => (
              <tr>
                <Buttons>
                  <Button
                    label="Edit"
                    onClick={() => this.editMenu(menu)}
                    outlined
                    secondary
                    small
                  />
                  <Button
                    label="Delete"
                    onClick={() => this.deleteMenu(menu)}
                    secondary
                    small
                  />
                  <Button
                    label="Items"
                    onClick={() => this.navigateToMenuItemsForMenu(menu.id)}
                    outlined
                    small
                  />
                </Buttons>
                <td>{menu.id}</td>
                <td>{menu.name}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    )
  }
}
