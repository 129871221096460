import {
  ListGroup,
  ListGroupItem,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input
} from "reactstrap"
import FormButtons from "./FormButtons"
import React from "react"

export default class PlaceMenusForm extends React.Component {
  constructor(props) {
    super(props)

    this.handleSubmit = this.handleSubmit.bind(this)
  }

  componentDidMount() {}

  handleSubmit(event) {
    event.preventDefault()

    const formData = new FormData(event.target)

    const menuIds = (formData.get("menuIds") || "")
      .split(",")
      .map((menuId) => parseInt(menuId))
    this.props.onSave({ placeId: this.props.place.id, menuIds: menuIds })
  }

  render() {
    return (
      <Modal isOpen={this.props.isOpen}>
        <Form onSubmit={this.handleSubmit}>
          <Input
            type="hidden"
            name="id"
            value={this.props.place ? this.props.place.id : ""}
          />
          <ModalHeader>
            {this.props.place ? "Menus for " + this.props.place.name : ""}
          </ModalHeader>
          <ModalBody>
            <ListGroup>
              {this.props.menus.map((menu) => (
                <ListGroupItem>
                  {menu.id} - {menu.name}
                </ListGroupItem>
              ))}
            </ListGroup>
            <FormGroup>
              <Label>Menu IDs</Label>
              <Input
                type="text"
                name="menuIds"
                defaultValue={
                  this.props.place && this.props.place.placeMenus
                    ? this.props.place.placeMenus
                        .sort(
                          (placeMenu1, placeMenu2) =>
                            placeMenu1.index - placeMenu2.index
                        )
                        .map((placeMenu) => placeMenu.menu.id)
                        .join(",")
                    : ""
                }
              />
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <FormButtons isSaving={false} onCancel={this.props.onCancel}>
              {this.props.place ? "Save" : ""}
            </FormButtons>
          </ModalFooter>
        </Form>
      </Modal>
    )
  }
}
