import {
  Badge,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row
} from "reactstrap"
import { Button } from "@fetch/ui"
import React from "react"

export default class Customer extends React.Component {
  render() {
    if (!this.props.user) {
      return null
    }

    return (
      <Modal isOpen={this.props.isOpen}>
        <ModalHeader>
          {this.props.user.firstName + " " + this.props.user.lastName}{" "}
          <Badge color="secondary">{this.props.user.type}</Badge>
        </ModalHeader>
        <ModalBody>
          <h2>Contact</h2>
          <Row>
            <Col xs="6">{this.props.user.email}</Col>
            <Col xs="6">{this.props.user.phoneNumber}</Col>
          </Row>
          <br />
          <h2>Login</h2>
          <Row>
            <Col xs="6">{this.props.user.accountProvider}</Col>
            <Col xs="6">{this.props.user.accountIdentifier}</Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button label="Close" onClick={this.props.close} outlined small />
        </ModalFooter>
      </Modal>
    )
  }
}
