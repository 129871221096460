import Request from '../lib/Request';

export default class UserManager {
  static getUsers(type, offset, limit, query) {
    return Request.get(`users?type=${type}&offset=${offset}&limit=${limit}&query=${query || ''}`).then(res => {
      return res.data.result.users
    })
  }

  static getDrivers(offset, limit, query) {
    return Request.get(`drivers?include=user&offset=${offset}&limit=${limit}&query=${query || ''}`).then(res => {
      return res.data.result.drivers
    })
  }

  static getAdmins(offset, limit, query) {
    return Request.get(`users?type=admin&include=permissions,notifications&offset=${offset}&limit=${limit}&query=${query || ''}`).then(res => {
      return res.data.result.users
    })
  }

  static getUserById(id, includeString) {
    return Request.get(`users/${id}?include=${includeString}`).then(res => {
      return res.data.result.user
    })
  }
}
