import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Input
} from "reactstrap"
import { Button } from "@fetch/ui"
import React from "react"
import Request from "../lib/Request"

export default class UserNotifications extends React.Component {
  constructor(props) {
    super(props)

    this.notificationToggled = this.notificationToggled.bind(this)
    this.addNotification = this.addNotification.bind(this)
    this.removeNotification = this.removeNotification.bind(this)
    this.notificationTypes = [
      "driverApplicationSubmitted",
      "customerPaymentFailed",
      "driverPayoutFailed",
      "placePaymentFailed",
      "fetchReported",
      "fetchCanceled",
      "invalidTransaction",
      "lingeringFetches",
      "regionOverloaded",
      "invalidPurchase"
    ]
  }

  componentDidMount() {}

  notificationToggled(event) {
    if (event.target.checked) {
      this.addNotification(event.target.name)
    } else {
      this.removeNotification(event.target.name)
    }
  }

  addNotification(notificationType) {
    Request.post("user_notifications", {
      userId: this.props.user ? this.props.user.id : null,
      type: notificationType
    })
      .then((res) => {
        console.log(res)
        this.props.onChange()
      })
      .catch((err) => {
        alert(err.response ? err.response.data.context.message : err)
      })
  }

  removeNotification(notificationType) {
    Request.post("user_notifications/remove", {
      userId: this.props.user ? this.props.user.id : null,
      type: notificationType
    })
      .then((res) => {
        console.log(res)
        this.props.onChange()
      })
      .catch((err) => {
        alert(err.response ? err.response.data.context.message : err)
      })
  }

  render() {
    return (
      <Modal toggle={this.props.onClose} isOpen={this.props.isOpen}>
        <Input
          type="hidden"
          name="id"
          value={this.props.user ? this.props.user.id : ""}
        />
        <ModalHeader toggle={this.props.onClose}>
          {this.props.user
            ? this.props.user.firstName + " " + this.props.user.lastName
            : ""}{" "}
          Notifications
        </ModalHeader>
        <ModalBody>
          {this.notificationTypes.map((notificationType) => (
            <FormGroup check>
              <Label check>
                <input
                  type="checkbox"
                  name={notificationType}
                  defaultChecked={
                    this.props.user
                      ? this.props.user.notifications
                          .map((userNotification) => userNotification.type)
                          .includes(notificationType)
                      : false
                  }
                  onClick={this.notificationToggled}
                />{" "}
                {notificationType}
              </Label>
            </FormGroup>
          ))}
        </ModalBody>
        <ModalFooter>
          <Button label="Close" onClick={this.props.onClose} outlined small />
        </ModalFooter>
      </Modal>
    )
  }
}
