import styled from 'styled-components';

const Box = styled.div.withConfig({
  displayName: "Box",
  componentId: "sc-1qpq2tq-0"
})(["box-shadow:1px 1px 10px rgb(0 0 0 / 19%);display:grid;padding:", ";row-gap:20px;"], ({
  padded = true
}) => padded ? "20px" : "0");

export { Box as default };
