// @flow
/* eslint-disable max-statements, init-declarations, no-magic-numbers, react/destructuring-assignment, prefer-destructuring, camelcase, no-dupe-else-if, object-shorthand, react/no-set-state, no-alert, no-ternary, lines-between-class-members, prefer-template, max-lines-per-function, react/jsx-handler-names, no-nested-ternary, react/jsx-no-bind, max-lines, react/no-unused-prop-types */

import { Col, Container, Row, Table } from "reactstrap"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import Badge from "@fetch/ui/Badge"
import Button from "@fetch/ui/Button"
import FetchData from "./FetchData"
import { Link } from "react-router-dom"
import Moment from "react-moment"
import QueryString from "querystring"
import RefundFetch from "./RefundFetch"
import Request from "../lib/Request"
import Select from "@fetch/ui/Select"
import User from "./User"

const INITIAL_REGION_ID = 1

const FETCH_STATES_OPTIONS = [
  { label: "All", value: "all" },
  { label: "Accepted", value: "accepted" },
  { label: "Arrived at Stop", value: "arrivedAtStop" },
  { label: "Canceled", value: "canceled" },
  { label: "Completed", value: "completed" },
  { label: "Completion Pending", value: "completionPending" },
  {
    label: "Completion Pending For Customer",
    value: "completionPendingForCustomer"
  },
  {
    label: "Completion Pending For Driver",
    value: "completionPendingForDriver"
  },
  { label: "Finished With Stop", value: "finishedWithStop" },
  { label: "Pending", value: "pending" },
  { label: "Reported", value: "reported" },
  { label: "Scheduled", value: "scheduled" },
  { label: "Terminated", value: "terminated" }
]

type Props = {|
  +currentUser: any,
  +location: any,
  +match: any,
  +history: any,
  +staticContext: any
|}

const Fetches = (props: Props) => {
  const queryString = props.location.search.slice(1)
  let customerId
  let driverId
  let offset
  if (queryString) {
    customerId = QueryString.parse(queryString).customer_id
    driverId = QueryString.parse(queryString).driver_id
    offset = QueryString.parse(queryString).offset
  }

  const offsetState = offset || 0

  const [fetch, setFetch] = useState(null)
  const [fetchData, setFetchData] = useState(null)
  const [fetchState, setFetchState] = useState("all")
  const [fetches, setFetches] = useState([])
  const [regionId, setRegionId] = useState(INITIAL_REGION_ID || 1)
  const [regions, setRegions] = useState([])
  const [showRefundFetch, setShowRefundFetch] = useState(null)
  const [user, setUser] = useState(null)

  const sortedFetches = useMemo(() => {
    if (fetchState === "scheduled") {
      return fetches.sort(
        (prev, next) => new Date(prev.scheduledOn) - new Date(next.scheduledOn)
      )
    }

    return fetches
  }, [fetchState, fetches])

  const loadData = useCallback(async () => {
    const params: any = {
      include: "customer,driver,stops,items",
      limit: 100,
      offset: offsetState,
      region: INITIAL_REGION_ID
    }

    if (customerId) {
      params.customer_id = customerId
    } else if (driverId) {
      params.driver_id = driverId
    }

    try {
      const regionsRes = await Request.get("regions")
      setRegions(regionsRes.data.result.regions)
    } catch (err) {
      alert(err.response ? err.response.data.context.message : err)
    }

    try {
      const res = await Request.get("fetches?" + QueryString.stringify(params))
      setFetches(res.data.result.fetches)
    } catch (err) {
      alert(err.response ? err.response.data.context.message : err)
    }
  }, [offsetState, customerId, driverId])

  useEffect(() => {
    loadData()
  }, [loadData])

  const showUser = (newUser) => {
    setUser(newUser)
  }

  const closeUser = () => {
    setUser(null)
  }

  const showFetch = (newFetch) => {
    Request.get("fetches/" + newFetch.id + "/text")
      .then((res) => {
        setFetch(newFetch)
        setFetchData(res.data)
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.log(err)
      })
  }

  const closeFetch = () => {
    setFetch(null)
    setFetchData(null)
  }

  const refundFetch = (data) => {
    Request.post("fetches/" + data.fetchId + "/refund", data)
      .then(() => {
        setShowRefundFetch(false)
        loadData()
        alert("success")
      })
      .catch((err) => {
        alert(err.response ? err.response.data.context.message : err)
      })
  }

  const cancelRefundFetch = () => {
    setFetch(null)
    setShowRefundFetch(false)
  }

  const terminateFetch = (newFetch) => {
    if (window.confirm("Are you sure you want to terminate this order?")) {
      Request.post("fetches/" + newFetch.id + "/terminate")
        .then(() => {
          loadData()
          alert("success")
        })
        .catch((err) => {
          alert(err.response ? err.response.data.context.message : err)
        })
    }
  }

  const rechargeFetch = (newFetch) => {
    if (
      window.confirm(
        "Are you sure you want to attempt to re-charge this order?"
      )
    ) {
      Request.post("fetches/" + newFetch.id + "/charge")
        .then(() => {
          loadData()
          alert("success")
        })
        .catch((err) => {
          alert(err.response ? err.response.data.context.message : err)
        })
    }
  }

  const changeRegion = (newRegionId) => {
    setRegionId(newRegionId)
    const params = {
      include: "customer,driver,stops,items",
      limit: 100,
      offset: offsetState,
      region_id: newRegionId
    }

    Request.get("fetches?" + QueryString.stringify(params))
      .then((res) => {
        setFetches(res.data.result.fetches)
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.log(err)
      })
  }

  const changeFetchState = (newFetchState) => {
    setFetchState(newFetchState)
    const params = {
      include: "customer,driver,stops,items",
      limit: 100,
      offset: offsetState,
      region_id: regionId,
      state: newFetchState === "all" ? undefined : newFetchState
    }

    Request.get("fetches?" + QueryString.stringify(params))
      .then((res) => {
        setFetches(res.data.result.fetches)
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.log(err)
      })
  }

  return (
    <div>
      <Container fluid>
        <Row style={{ marginTop: "32px" }}>
          <Col md="6" xs="0" />
          <Col md="3" xs="6">
            <Select
              label="Order State"
              name="fetchState"
              onChange={changeFetchState}
              options={FETCH_STATES_OPTIONS}
              value={fetchState}
            />
          </Col>
          <Col md="3" xs="6">
            <Select
              label="Region"
              name="region"
              onChange={changeRegion}
              options={regions.map((region) => ({
                label: region.name,
                value: region.id
              }))}
              value={regionId}
            />
          </Col>
        </Row>

        <Row>
          <Col xs="6">
            <h1>
              {"Orders"}
              {customerId
                ? ` for Customer ${customerId}`
                : driverId
                ? ` for Driver ${driverId}`
                : ""}
            </h1>
          </Col>
        </Row>
      </Container>
      <User close={closeUser} isOpen={Boolean(user)} user={user} />
      <FetchData
        close={closeFetch}
        data={fetchData}
        fetch={fetch}
        isOpen={Boolean(fetchData)}
      />
      <RefundFetch
        fetch={fetch}
        isOpen={Boolean(showRefundFetch)}
        onCancel={cancelRefundFetch}
        onSubmit={refundFetch}
      />
      <Table bordered hover striped>
        <thead>
          <tr>
            <th>{"Actions"}</th>
            <th>{"Id"}</th>
            {fetchState === "scheduled" ? (
              <th>{"Scheduled For"}</th>
            ) : (
              <th>{"Created"}</th>
            )}
            {fetchState === "all" ? <th>{"State"}</th> : null}
            <th>{"Customer"}</th>
            <th>{"Driver"}</th>
            <th>{"Stops"}</th>
          </tr>
        </thead>
        <tbody>
          {sortedFetches.map((item) => (
            <tr key={item.id}>
              <td>
                {item.state === "pending" ||
                item.state === "accepted" ||
                item.state === "arrivedAtStop" ||
                item.state === "finishedWithStop" ? (
                  <Button
                    label="Terminate"
                    onClick={() => terminateFetch(item)}
                    outlined
                    secondary
                    small
                  />
                ) : (
                  ""
                )}
                {item.chargeState === "charged" &&
                item.refundAmount === null ? (
                  <Button
                    label="Refund"
                    onClick={() => refundFetch(item)}
                    outlined
                    small
                  />
                ) : (
                  ""
                )}
                {item.chargeState === "chargeFailure" ? (
                  <Button
                    label="Re-Charge"
                    onClick={() => rechargeFetch(item)}
                    secondary
                    small
                  />
                ) : (
                  ""
                )}
              </td>
              <td>
                <Link onClick={() => showFetch(item)} to="#">
                  {item.id}
                </Link>
              </td>
              <td>
                <Moment format="YYYY/MM/DD HH:mm">
                  {fetchState === "scheduled"
                    ? item.scheduledOn
                    : item.createdAt}
                </Moment>
              </td>
              {fetchState === "all" ? <td>{item.state}</td> : null}
              <td
                style={{
                  display: "grid",
                  justifyItems: "start",
                  rowGap: "4px"
                }}
              >
                <Link
                  onClick={() =>
                    showUser(item.customer ? item.customer.user : null)
                  }
                  to="#"
                >
                  {item.customer && item.customer.user
                    ? item.customer.user.firstName +
                      " " +
                      item.customer.user.lastName
                    : ""}
                </Link>
                {item.customer?.user?.hasSubscription ? (
                  <Badge color="secondary" label="Fetch Unlimited" />
                ) : null}
              </td>
              <td>
                <Link
                  onClick={() =>
                    showUser(item.driver ? item.driver.user : null)
                  }
                  to="#"
                >
                  {item.driver && item.driver.user
                    ? item.driver.user.firstName +
                      " " +
                      item.driver.user.lastName
                    : ""}
                </Link>
              </td>
              <td>
                <ul>
                  {item.stops.map((stop) => (
                    <li key={stop.id}>{stop.type + ": " + stop.name}</li>
                  ))}
                </ul>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  )
}

export default Fetches
