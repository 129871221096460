/* eslint-disable max-statements, react/require-optimization, vars-on-top, no-var, init-declarations, no-magic-numbers, react/destructuring-assignment, prefer-destructuring, camelcase, no-dupe-else-if, object-shorthand, react/no-set-state, no-alert, no-ternary, lines-between-class-members, prefer-template, max-lines-per-function, react/jsx-handler-names, no-nested-ternary, react/jsx-no-bind, max-lines, react/no-access-state-in-setstate, class-methods-use-this, react/jsx-max-depth, no-param-reassign, id-length */

import {
  Col,
  Container,
  Input,
  InputGroup,
  InputGroupAddon,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table
} from "reactstrap"
import { Button } from "@fetch/ui"
import Constants from "../lib/Constants"
import LoadingIndicator from "./LoadingIndicator"
import React from "react"
import Request from "../lib/Request"
import SearchBar from "./SearchBar"
import UserManager from "../lib/UserManager"
import handleError from "../lib/ErrorHandler"
import styled from "styled-components"

require("../lib/Extensions")

const Buttons = styled.td`
  column-gap: 10px;
  display: grid;
  grid-template-columns: auto auto;
`

export default class Customers extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      customerUsers: [],
      isLoading: false,
      offset: 0,
      pageSize: 10,
      query: null
    }

    this.loadData = this.loadData.bind(this)
    this.search = this.search.bind(this)
    this.suspendCustomer = this.suspendCustomer.bind(this)
    this.unsuspendCustomer = this.unsuspendCustomer.bind(this)
    this.viewOrders = this.viewOrders.bind(this)
  }

  componentDidMount() {
    this.loadData()
  }

  loadData(offset, query) {
    this.setState({ isLoading: true })

    if (!offset) {
      offset = 0
    }

    return UserManager.getUsers(
      Constants.UserType.customer,
      offset,
      this.state.pageSize,
      query
    )
      .then((users) => {
        this.setState({
          customerUsers: users,
          isLoading: false,
          offset: offset || 0,
          query: query
        })
      })
      .catch((err) => {
        handleError(err)
        this.setState({ isLoading: false })
      })
  }

  search(searchTerm) {
    this.loadData(0, searchTerm.trim())
  }

  nextPage() {
    this.loadData(this.state.offset + this.state.pageSize, this.state.query)
  }

  previousPage() {
    const offset = Math.max(this.state.offset - this.state.pageSize, 0)
    this.loadData(offset, this.state.query)
  }

  changePageSize(newPageSize) {
    this.setState({ pageSize: newPageSize })
    this.loadData(0, this.state.query)
  }

  suspendCustomer(customerUser) {
    if (
      window.confirm(
        `Are you sure you want to suspend ${customerUser.firstName}?`
      )
    ) {
      Request.post("users/" + customerUser.id + "/suspend")
        .then(() => {
          this.loadData(this.state.offset, this.state.query)
        })
        .catch((err) => {
          handleError(err)
        })
    }
  }

  unsuspendCustomer(customerUser) {
    if (
      window.confirm(
        `Are you sure you want to unsuspend ${customerUser.firstName}?`
      )
    ) {
      Request.post("users/" + customerUser.id + "/unsuspend")
        .then(() => {
          this.loadData(this.state.offset, this.state.query)
        })
        .catch((err) => {
          handleError(err)
        })
    }
  }

  viewOrders(customerUser) {
    UserManager.getUserById(customerUser.id, "customer")
      .then((user) => {
        window.location.href = `/orders?customer_id=${user.customer.id}`
      })
      .catch((err) => {
        handleError(err)
      })
  }

  render() {
    return (
      <div>
        <Container fluid>
          <Row>
            <Col xs="5">
              <h1>
                {"Customers " +
                  (this.state.query ? 'for "' + this.state.query + '"' : "")}
                <LoadingIndicator isLoading={this.state.isLoading} />
              </h1>
            </Col>
            <Col className="d-flex align-items-center" xs="3">
              <SearchBar
                onClear={() => this.loadData(0, "")}
                onSearch={this.search}
                query={this.state.query}
              />
            </Col>
            <Col className="d-flex align-items-center" xs="4">
              <Pagination>
                <PaginationItem disabled={this.state.offset === 0}>
                  <PaginationLink
                    href="#"
                    onClick={() => this.previousPage()}
                    previous
                  />
                </PaginationItem>
                <PaginationItem>
                  <PaginationLink>
                    {Math.round(this.state.offset / this.state.pageSize) + 1}
                  </PaginationLink>
                </PaginationItem>
                <PaginationItem
                  disabled={
                    this.state.customerUsers.length < this.state.pageSize
                  }
                >
                  <PaginationLink
                    href="#"
                    next
                    onClick={() => this.nextPage()}
                  />
                </PaginationItem>
              </Pagination>
              &nbsp;
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  {"Page Size: "}
                </InputGroupAddon>
                <Input
                  name="pageSize"
                  onChange={(e) => this.changePageSize(`${e.target.value}`)}
                  type="select"
                >
                  <option>{"10"}</option>
                  <option>{"50"}</option>
                  <option>{"200"}</option>
                  <option>{"1000"}</option>
                </Input>
              </InputGroup>
            </Col>
          </Row>
        </Container>
        <Table bordered responsive striped>
          <thead>
            <tr>
              <th width="360">{"Actions"}</th>
              <th width="80">{"User Id"}</th>
              <th>{"Name"}</th>
              <th>{"Email"}</th>
              <th>{"Phone"}</th>
            </tr>
          </thead>
          <tbody>
            {this.state.customerUsers.map((customerUser) => (
              <tr key={customerUser.id}>
                <Buttons>
                  {customerUser.suspended ? (
                    <Button
                      label="Unsuspend"
                      onClick={() => this.unsuspendCustomer(customerUser)}
                      outlined
                      secondary
                      small
                    />
                  ) : (
                    <Button
                      label="Suspend"
                      onClick={() => this.suspendCustomer(customerUser)}
                      outlined
                      secondary
                      small
                    />
                  )}
                  <Button
                    label="View Orders"
                    onClick={() => this.viewOrders(customerUser)}
                    outlined
                    small
                  />
                </Buttons>
                <td>{customerUser.id}</td>
                <td>{customerUser.firstName + " " + customerUser.lastName}</td>
                <td>{customerUser.email}</td>
                <td>{customerUser.phone}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    )
  }
}
