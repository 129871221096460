import { Badge, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import { Button } from "@fetch/ui"
import React from "react"

class FetchData extends React.Component {
  render() {
    if (!this.props.fetch) {
      return null
    }

    return (
      <Modal isOpen={this.props.isOpen} toggle={this.props.close}>
        <ModalHeader toggle={this.props.close}>
          Order #{this.props.fetch.id}{" "}
          <Badge color="secondary">{this.props.fetch.state}</Badge>
        </ModalHeader>
        <ModalBody>
          <pre style={{ whiteSpace: "pre-wrap" }}>{this.props.data}</pre>
        </ModalBody>
        <ModalFooter>
          <Button label="Close" onClick={this.props.close} outlined small />
        </ModalFooter>
      </Modal>
    )
  }
}

export default FetchData
